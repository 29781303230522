import { ContactFormFields } from '@transcend-io/contact-form-schema';
import { cleanEmail } from '@transcend-io/segment/core';

/**
 * Submission handler for ChiliPiper scheduling modal
 *
 * @see https://help.chilipiper.com/hc/en-us/articles/360053800373-About-Concierge-Snippet-and-JS-API
 */
export function triggerSchedulingModal(fields: ContactFormFields) {
  try {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      if (!window.ChiliPiper) {
        throw new Error('ChiliPiper was not loaded!');
      }

      const cleanedEmail = cleanEmail(fields.email);

      if (cleanedEmail === 'force-error@transcend.io') {
        throw new Error('Force error');
      }

      window.ChiliPiper.submit('transcend', 'contact-us', {
        trigger: 'ThirdPartyForm',
        lead: {
          email: cleanedEmail,
          firstName: fields.firstName,
          lastName: fields.lastName,
          company: fields.company,
          state: fields.state,
          country: fields.country,
          companySize: fields.companySize,
        },
      });
    }
  } catch (err) {
    console.error(err);
  }
}
