import type { SanityRichTextSection } from '#/lib/sanity/queries/richTextSection';

import { Headline } from './Headline';
import { RichText } from './RichText';

export function RichTextSection(
  props: SanityRichTextSection & { shareButtons?: React.ReactNode },
) {
  return (
    <div className="p-main mb-100">
      <div className="relative mx-auto mt-50 max-w-735">
        {props.pageTitle ? (
          <Headline value={props.pageTitle} variant={2} className="mb-50" />
        ) : null}
        {props.shareButtons}
        <RichText value={props.content} />
      </div>
    </div>
  );
}
