import type { SVGProps } from 'react';

export function ArrowLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 15 15" fill="none" {...props}>
      <path
        d="M14.0374 6.51236L3.28489 6.51236L7.98249 1.69164C8.35791 1.30638 8.35791 0.674154 7.98249 0.288892C7.89343 0.197314 7.78765 0.124661 7.6712 0.0750886C7.55475 0.0255166 7.42991 8.86008e-08 7.30384 8.70974e-08C7.17777 8.5594e-08 7.05293 0.0255166 6.93648 0.0750886C6.82003 0.124661 6.71425 0.197314 6.62519 0.288892L0.281513 6.79883C0.192275 6.89022 0.121476 6.99878 0.0731706 7.11828C0.0248648 7.23778 9.10358e-08 7.36589 8.9493e-08 7.49527C8.79502e-08 7.62465 0.0248648 7.75275 0.0731706 7.87226C0.121476 7.99176 0.192275 8.10032 0.281513 8.1917L6.61556 14.7115C6.70469 14.803 6.81049 14.8755 6.92693 14.925C7.04337 14.9745 7.16818 15 7.29421 15C7.42025 15 7.54505 14.9745 7.66149 14.925C7.77794 14.8755 7.88374 14.803 7.97286 14.7115C8.06198 14.6201 8.13268 14.5115 8.18091 14.392C8.22914 14.2725 8.25397 14.1444 8.25397 14.0151C8.25397 13.8858 8.22914 13.7577 8.18091 13.6382C8.13268 13.5187 8.06198 13.4101 7.97286 13.3187L3.28489 8.48806L14.0374 8.48806C14.5668 8.48806 15 8.04353 15 7.50021C15 6.95689 14.5668 6.51236 14.0374 6.51236Z"
        className="fill-current"
      />
    </svg>
  );
}
