import type { SanityPartnerGrid } from '#/lib/sanity/queries/sections';
import { cn } from '#/lib/utils';

import { Headline } from './Headline';
import { Media } from './Media';

export function PartnerGrid(props: SanityPartnerGrid) {
  return (
    <div className="p-main text-center">
      <div
        className={cn('mx-auto max-w-[480px] text-balance', {
          'mb-45': props.bodyCopy,
          'mb-60': !props.bodyCopy,
        })}
      >
        <Headline value={props.headline} />
        {props.bodyCopy ? <p className="mt-20">{props.bodyCopy}</p> : null}
      </div>
      <div
        className={cn('mx-auto max-w-[980px]', {
          'grid grid-cols-2 gap-x-10 gap-y-40 s:grid-cols-3 s:gap-x-20 m:grid-cols-4':
            props.gridType === 'people',
          'flex flex-wrap justify-center gap-x-85 gap-y-40':
            props.gridType === 'logos',
        })}
      >
        {props.items.map((item) => (
          <div key={item._key} className="flex flex-col items-center">
            <Media
              media={item.image}
              className={cn({
                'size-96 rounded-full border-1 border-grayscale-02 m:size-130':
                  props.gridType === 'people',
                'h-48 w-auto': props.gridType === 'logos',
              })}
              sizes={
                props.gridType === 'people'
                  ? ['96px', { m: '130px' }]
                  : `${(48 * item.image.width) / item.image.height}px`
              }
              mediaClassName={
                props.gridType === 'logos' ? 'object-contain' : 'object-cover'
              }
              placeholder={props.gridType === 'people'}
            />
            {props.gridType === 'people' ? (
              <>
                <p className="mt-15 font-[440] leading-150 -tracking-1 m:mt-20">
                  {item.title}
                </p>
                {item.caption ? (
                  <p className="mt-5 text-balance text-12 leading-150 s:text-14 m:mt-10 m:text-16">
                    {item.caption}
                  </p>
                ) : null}
              </>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}
