import type { SanityLogoGrid } from '#/lib/sanity/queries/sections';
import { Link } from '#/components/Link';

import { Button } from './Button';
import { Headline } from './Headline';
import { Media } from './Media';

export function LogoGrid(props: SanityLogoGrid) {
  return (
    <div
      className="p-main rounded-20 py-50 m:rounded-35 m:py-100"
      style={{ background: props.background }}
    >
      <div className="mx-auto max-w-[1235px]">
        <Headline
          value={props.headline}
          className="mx-auto mb-25 max-w-[735px] text-center m:mb-40"
        />
        <div className="-mx-5 flex flex-wrap gap-y-10 m:-mx-10 m:gap-y-20">
          {props.items.map((item) => {
            return (
              <div key={item._key} className="w-1/2 px-5 m:px-10 l:w-1/3">
                <Link
                  className="group relative block min-h-100 cursor-pointer rounded-10 pt-25 text-center m:min-h-180 m:rounded-15 m:pt-60"
                  href={item.link.href}
                  target={item.link.href}
                >
                  <div className="absolute inset-0 scale-95 rounded-10 bg-cloud opacity-0 transition duration-250 group-hover:scale-100 group-hover:opacity-100 group-hover:duration-500 m:rounded-15"></div>
                  <div className="z-1 flex flex-col items-center">
                    <Media
                      media={item.logo}
                      className="mb-5 w-auto scale-[0.8] transition duration-250 group-hover:-translate-y-5 group-hover:duration-500 m:mb-15 m:scale-100"
                      mediaClassName="object-contain"
                      style={{ width: item.logoWidth }}
                      sizes={`${item.logoWidth}px`}
                    />
                    <div className="opacity-0 transition duration-250 group-hover:translate-y-5 group-hover:opacity-100 group-hover:duration-500">
                      <Button variant="link" asDiv>
                        {item.link.title}
                      </Button>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
