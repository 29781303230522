import clsx from 'clsx';

import type { SanityHeroTwoUp } from '#/lib/sanity/queries/sections';

import { BasicText } from './BasicText';
import { Button } from './Button';
import { Headline } from './Headline';
import { Media } from './Media';

export function HeroTwoUp(props: SanityHeroTwoUp) {
  return (
    <div
      className={clsx('p-main pb-40 m:pb-100', {
        'mt-[calc(var(--header-height)*-1)] pt-[calc(var(--header-height)+3rem)]':
          props.sectionIndex === 0,
        'pt-30': props.sectionIndex !== 0,
        'rounded-20 m:rounded-35': props.hasRoundCorners,
      })}
      style={{
        background: props.background ?? undefined,
        color: props.foreground ?? undefined,
      }}
    >
      <div className="mx-auto max-w-1115 gap-x-20 m:flex">
        <div className="flex-1">
          <Headline
            as={props.sectionIndex === 0 ? 'h1' : 'h2'}
            value={props.headline}
            overline={props.overline}
            variant={2}
            className="mb-10 m:mb-25"
          />
          {props.bodyCopy ? (
            <BasicText className="text max-w-[430px]" value={props.bodyCopy} />
          ) : null}
          {props.button.href ? (
            <Button
              className="mt-30"
              href={props.button.href}
              target={props.button.target}
              variant={props.button.variant}
            >
              {props.button.title}
            </Button>
          ) : null}
        </div>
        <div className="media mt-30 flex-1 m:mt-0">
          <Media
            media={props.media}
            sizes={['100vw', { m: '50vw', '1165px': '560px' }]}
          />
        </div>
      </div>
    </div>
  );
}
