import type { SanityTestimonialGrid } from '#/lib/sanity/queries/sections';
import { cn } from '#/lib/utils';

import { Button } from './Button';
import { Headline } from './Headline';
import { Media } from './Media';

export function TestimonialGrid(props: SanityTestimonialGrid) {
  return (
    <div className="p-main">
      <div className="mx-auto max-w-[1235px]">
        <div className="-mx-10 flex flex-wrap gap-y-20">
          {props.items.map((item) => {
            const wrapperCx = 'px-10 w-full m:w-1/2 l:w-1/3';
            if (item._type === 'testimonialCard') {
              return (
                <div key={item._key} className={wrapperCx}>
                  <div className="flex h-full flex-col justify-between rounded-15 border-1 border-grayscale-02 px-20 pb-30 pt-30 m:rounded-25 m:px-30 l:px-40 l:pb-50 l:pt-40">
                    {item.image && item.logoWidth ? (
                      <Media
                        media={item.image}
                        className="mb-70 w-auto origin-top-left scale-[0.8] l:scale-100"
                        mediaClassName="object-contain"
                        style={{ width: item.logoWidth }}
                        sizes={`${item.logoWidth}px`}
                      />
                    ) : null}
                    <div className="mt-auto">
                      <p className="text-pretty text-22 font-[440] leading-137 -tracking-2 before:content-['“'] after:content-['”'] m:text-24">
                        {item.quote}
                      </p>
                      <div className="mt-30 space-y-5">
                        <p className="text-16 font-[440] leading-116 tracking-0 text-grayscale-06">
                          {item.name}
                        </p>
                        {item.role ? (
                          <p className="text-14 font-[440] leading-116 tracking-0 text-grayscale-05">
                            {item.role}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (item._type === 'inlineCallToAction') {
              return (
                <div
                  key={item._key}
                  className={cn(
                    wrapperCx,
                    'grid place-items-center gap-y-25 pb-50 pt-40 text-center',
                  )}
                >
                  <div>
                    <Headline className="mb-25" value={item.headline} />
                    <Button
                      variant="link"
                      href={item.link.href}
                      target={item.link.href}
                    >
                      {item.link.title}
                    </Button>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}
