import { createContext, useContext, useState, type ReactNode } from 'react';
import { useAnimationFrame } from 'framer-motion';

type Theme = 'light' | 'dark';

type NavThemeContextType = {
  theme: Theme;
  registerSection: (id: string, top: number, bottom: number) => void;
  unregisterSection: (id: string) => void;
};

type NavThemeProviderProps = {
  children: ReactNode;
};

const NavThemeContext = createContext<NavThemeContextType | undefined>(
  undefined,
);

export function useNavTheme(): NavThemeContextType {
  const context = useContext(NavThemeContext);

  if (context === undefined) {
    throw new Error('useNavTheme must be used within a NavThemeProvider');
  }

  return context;
}

export function NavThemeProvider({ children }: NavThemeProviderProps) {
  const [theme, setTheme] = useState<Theme>('light');
  const [sections, setSections] = useState(
    new Map<string, [top: number, bottom: number]>(),
  );

  const registerSection = (
    sectionId: string,
    ...section: [top: number, bottom: number]
  ) => {
    setSections((sections) => sections.set(sectionId, section));
  };

  const unregisterSection = (sectionId: string) => {
    setSections((sections) => {
      sections.delete(sectionId);
      return sections;
    });
  };

  useAnimationFrame(() => {
    const y = Math.max(0, window.scrollY);
    const newTheme = Array.from(sections.values()).find(
      ([top, bottom]) => y >= top && y < bottom,
    )
      ? 'dark'
      : 'light';

    if (newTheme !== theme) {
      setTheme(newTheme);
    }
  });

  return (
    <NavThemeContext.Provider
      value={{ theme, registerSection, unregisterSection }}
    >
      {children}
    </NavThemeContext.Provider>
  );
}
