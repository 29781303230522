import type { AppProps } from 'next/app';

import '#/styles/globals.css';

import { strict } from 'assert';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { makeExtractFromUnionFunction } from '@repo/shared/helpers/makeExtractFromUnionFunction';
import { Analytics as AnonymousAnalytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { ConsentProvider } from '#/lib/consent-manager';
import { useDataDogRum } from '#/lib/datadog/rum';
import { AzeretMono, GTPlanar } from '#/lib/fonts';
import { NavThemeProvider } from '#/lib/nav-theme';
import type { UnionedPageProps } from '#/lib/sanity/unionedPageType';
import { AnalyticsProvider } from '#/lib/segment';
import { cn } from '#/lib/utils';
import { Link } from '#/components/Link';
import { SiteFooter } from '#/components/SiteFooter';
import { SiteHeader } from '#/components/SiteHeader/SiteHeader';

/**
 * The Airgap source URL for the Consent Manager
 * @see https://app.transcend.io/consent-manager/developer-settings
 */
strict(process.env.NEXT_PUBLIC_AIRGAP_BUNDLE_ID);

/**
 * The public site key for reCAPTCHA bot filtering.
 * @see https://www.google.com/recaptcha/admin/site/341894637/settings
 */
strict(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY);

export default function App({
  Component,
  pageProps,
}: AppProps<UnionedPageProps & { isHomepage?: boolean; draftMode?: boolean }>) {
  const { isHomepage, site, page, draftMode } = pageProps;

  strict(process.env.NEXT_PUBLIC_AIRGAP_BUNDLE_ID);
  strict(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY);

  const router = useRouter();
  const currentPath = router.asPath;

  // Initialize DataDog RUM
  useDataDogRum({ draftMode: !!draftMode });

  // Helper to safely extract page values from the unioned page object
  const getFromPage = makeExtractFromUnionFunction(page);

  const _titleFromPageObject = getFromPage('title');

  // Extract meta tags for this page, falling back to site defaults
  const title =
    isHomepage || !_titleFromPageObject
      ? site.title
      : `${_titleFromPageObject} | ${site.title}`;
  const description =
    getFromPage('description') ?? getFromPage('excerpt') ?? site.description;
  const ogTitle =
    getFromPage('openGraphTitle') ??
    _titleFromPageObject ??
    site.openGraphTitle;
  const ogDescription =
    getFromPage('openGraphDescription') ??
    getFromPage('excerpt') ??
    site.openGraphDescription;
  const ogImage =
    getFromPage('openGraphImage') ??
    getFromPage('thumbnailImage') ??
    site.openGraphImage;
  const deIndex = getFromPage('deIndex') ?? false;

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta content={description} name="description" />

        <meta content="website" property="og:type" />
        <meta
          content={`https://transcend.io${currentPath}`}
          property="og:url"
        />
        <meta content="Transcend" property="og:site_name" />
        <meta content={ogTitle} property="og:title" />
        <meta content={ogDescription} property="og:description" />
        {deIndex && <meta content="noindex" property="robots" />}

        {ogImage ? (
          <>
            <meta content={ogImage.url} property="og:image" />
            {ogImage.alt && (
              <meta content={ogImage.alt} property="og:image:alt" />
            )}
            {ogImage.width && (
              <meta content={String(ogImage.width)} property="og:image:width" />
            )}
            {ogImage.height && (
              <meta
                content={String(ogImage.height)}
                property="og:image:height"
              />
            )}
          </>
        ) : null}

        <meta content="summary_large_image" name="twitter:card" />
        <meta
          content={`https://transcend.io${currentPath}`}
          name="twitter:url"
        />
        <meta content={ogTitle} name="twitter:title" />
        <meta content={ogDescription} name="twitter:description" />
        {ogImage ? (
          <>
            <meta content={ogImage.url} name="twitter:image" />
            {ogImage.alt && (
              <meta content={ogImage.alt} name="twitter:image:alt" />
            )}
          </>
        ) : null}

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5f5bf7" />
        <meta name="msapplication-TileColor" content="#5f5bf7" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <ConsentProvider
        airgapSrc={`https://transcend-cdn.com/cm/${process.env.NEXT_PUBLIC_AIRGAP_BUNDLE_ID}/airgap.js`}
      >
        <AnalyticsProvider>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
            useRecaptchaNet={true}
            scriptProps={{
              async: false,
              defer: true,
              nonce: undefined, // optional, default undefined
            }}
          >
            <NavThemeProvider>
              <div
                className={cn(
                  AzeretMono.variable,
                  GTPlanar.variable,
                  'font-planar',
                )}
              >
                <SiteHeader
                  {...site.header}
                  announcement={site.announcement}
                  menuBarStyle={getFromPage('menuBarStyle') ?? 'default'}
                />
                <Component
                  key={page._id}
                  {...pageProps}
                  className="overflow-x-hidden"
                />
                <SiteFooter {...site.footer} />
                {draftMode && (
                  <Link
                    prefetch={false}
                    href={`/api/disable-draft?path=${currentPath}`}
                    className="fixed bottom-0 left-1/2 z-[9999] -translate-x-1/2 rounded-t-12 border-1 border-b-0 border-cloud bg-charcoal px-12 pb-5 pt-3 text-14 font-normal leading-150 text-cloud drop-shadow-lg "
                  >
                    Exit Preview Mode
                  </Link>
                )}
                <AnonymousAnalytics mode={draftMode ? 'development' : 'auto'} />
                {/* Draft / Preview mode will pollute the SpeedInsights data */}
                {!draftMode && <SpeedInsights />}
              </div>
            </NavThemeProvider>
          </GoogleReCaptchaProvider>
        </AnalyticsProvider>
      </ConsentProvider>
    </>
  );
}
