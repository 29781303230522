import clsx from 'clsx';

import type { SanityHeroBasic } from '#/lib/sanity/queries/sections';

import { Button } from './Button';
import { Headline } from './Headline';

export function HeroBasic(props: SanityHeroBasic) {
  const hasBackground = props.background;
  const isFirst = props.sectionIndex === 0;
  return (
    <div
      className={clsx('p-main text-center', {
        'mt-[calc(var(--header-height)*-1)] pb-100 pt-[calc(var(--header-height)+3rem)]':
          isFirst && hasBackground,
        'pt-30': isFirst && !hasBackground,
        'py-80 m:py-150': !isFirst && hasBackground,
        'rounded-b-20 m:rounded-b-35': props.hasRoundCorners,
      })}
      style={{
        background: props.background ?? undefined,
        color: props.foreground ?? undefined,
      }}
    >
      <div
        className={clsx('mx-auto', {
          'max-w-[735px]': props.headlineSize === 'normal',
          'max-w-[980px]': props.headlineSize === 'large',
        })}
      >
        <Headline
          as={props.sectionIndex === 0 ? 'h1' : 'h2'}
          variant={({ large: 1, normal: 2 } as const)[props.headlineSize]}
          value={props.headline}
          overline={props.overline}
        />
        {props.bodyCopy ? (
          <p
            className={clsx(
              'mx-auto mt-12 max-w-[480px] text-balance m:mt-25',
              {
                'text-16 leading-140 m:text-20 m:leading-150':
                  props.headlineSize === 'large',
                'text-body': props.headlineSize === 'normal',
              },
            )}
          >
            {props.bodyCopy}
          </p>
        ) : null}
        {props.button.href ? (
          <Button {...props.button} className="mt-30">
            {props.button.title}
          </Button>
        ) : null}
      </div>
    </div>
  );
}
