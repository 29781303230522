// @ts-nocheck

import { createContext, useContext, useRef } from 'react';
import { useRect } from '@darkroom.engineering/hamo';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/dist/MotionPathPlugin';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import type { SanityProblemVisualization } from '#/lib/sanity/queries/sections';
import { cn } from '#/lib/utils';

import { Headline } from './Headline';
import { Overline } from './Overline';

gsap.registerPlugin(useGSAP, ScrollTrigger, MotionPathPlugin);

type ConfettiContextType = {
  containerRect: DOMRect;
};

const ConfettiContext = createContext<ConfettiContextType | undefined>(
  undefined,
);

export function ProblemVisualization(props: SanityProblemVisualization) {
  const [containerRef, containerRect] = useRect({
    debounce: 0,
    ignoreTransform: true,
  });
  const [contentRef, contentRect] = useRect({
    debounce: 0,
    ignoreTransform: true,
  });

  return (
    <div
      ref={containerRef}
      className="p-main relative flex items-center justify-center overflow-clip bg-charcoal pb-450 pt-300 text-cloud"
    >
      <div className="absolute -bottom-1 left-1/2 w-full -translate-x-1/2 s:w-800 800:w-1536 2xl:w-full">
        <svg className="w-full" viewBox="0 0 1536 173" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M768.499 0C1064.66 0 1334.26 65.3412 1536 172.301L0 172.831C201.852 65.5572 471.85 0 768.499 0ZM767.5 159C980.958 159 1154 126.765 1154 87C1154 47.2355 980.958 15 767.5 15C554.042 15 381 47.2355 381 87C381 126.765 554.042 159 767.5 159Z"
            className="fill-current"
          />
        </svg>
      </div>

      <div className="relative mx-auto mb-75 max-w-[480px] text-center">
        <div ref={contentRef}>
          {props.overline ? (
            <Overline className="mb-15" as="h2">
              {props.overline}
            </Overline>
          ) : null}
          <Headline className="mb-25" as="h3" value={props.headline} />
          <p>{props.bodyCopy}</p>
        </div>

        {/* Confetti */}
        <ConfettiContext.Provider value={{ containerRect, contentRect }}>
          {/**
           * Confetti positioning guide
           * - Positon relative to the content div to avoid overlapping text
           * - Partition the section into 12 sections surrounding the content to stay organized
           *
           *    1  |  2   |   3  |  4
           * ---------------------------
           *    5  |      |      |  6
           * ------| contentRef  |------
           *    7  |      |      |  8
           * ---------------------------
           *    9  |  10  |  11  |  12
           */}

          {/* === Section 1 === */}
          <Circle
            section={1}
            className="bottom-[calc(100%+172px)] right-[198%] bg-gold-04"
          />
          <Circle
            section={1}
            className="bottom-[calc(100%+211px)] right-[153%] bg-lush-04"
          />
          <Circle
            section={1}
            className="bottom-[calc(100%+172px)] right-[98%] bg-gold-04"
          />
          <Circle
            section={1}
            className="bottom-[calc(100%+91px)] right-[174%] bg-gold-04"
          />
          <Circle
            section={1}
            className="bottom-[calc(100%+72px)] right-[140%] bg-lush-04"
          />
          <Circle
            section={1}
            className="bottom-[calc(100%+111px)] right-[122%] bg-amethyst-04"
          />
          <Circle
            section={1}
            className="bottom-[calc(100%+17px)] right-[105%] bg-flame-04"
          />
          <Circle
            section={1}
            className="bottom-[calc(100%-14px)] right-[156%] bg-sky-04"
          />

          {/* === Section 2 === */}
          <Circle
            section={2}
            className="bottom-[calc(100%+123px)] left-[33%] bg-flame-04"
          />
          <Circle
            section={2}
            className="bottom-[calc(100%+211px)] left-[25%] bg-sky-04"
          />
          <Circle
            section={2}
            className="bottom-[calc(100%+84px)] left-[7.5%] bg-amethyst-04"
          />

          {/* === Section 3 === */}
          <Circle
            section={3}
            className="bottom-[calc(100%+119px)] right-[36%] bg-gold-04"
          />
          <Circle
            section={3}
            className="bottom-[calc(100%+202px)] right-[22%] bg-flame-04"
          />
          <Circle
            section={3}
            className="bottom-[calc(100%+92px)] right-[10%] bg-sky-04"
          />
          <Circle
            section={3}
            className="bottom-[calc(100%+148px)] right-[-2%] bg-gold-04"
          />

          {/* === Section 4 === */}
          <Circle
            section={4}
            className="bottom-[calc(100%+211px)] left-[100%] bg-amethyst-04"
          />
          <Circle
            section={4}
            className="bottom-[calc(100%+45px)] left-[110%] bg-lush-04"
          />
          <Circle
            section={4}
            className="bottom-[calc(100%+152px)] left-[121%] bg-amethyst-04"
          />
          <Circle
            section={4}
            className="bottom-[calc(100%+6px)] left-[137%] bg-flame-04"
          />
          <Circle
            section={4}
            className="bottom-[calc(100%+230px)] left-[145%] bg-flame-04"
          />
          <Circle
            section={4}
            className="bottom-[calc(100%+56px)] left-[151%] bg-sky-04"
          />
          <Circle
            section={4}
            className="bottom-[calc(100%-18px)] left-[164%] bg-flame-04"
          />
          <Circle
            section={4}
            className="bottom-[calc(100%+128px)] left-[179%] bg-lush-04"
          />
          <Circle
            section={4}
            className="bottom-[calc(100%+33px)] left-[182%] bg-flame-04"
          />

          {/* === Section 5 === */}
          <Circle section={5} className="right-[194%] top-[9px] bg-gold-04" />
          <Circle
            section={5}
            className="right-[170%] top-[28px] bg-amethyst-04"
          />
          <Circle section={5} className="right-[134%] top-[0px] bg-flame-04" />
          <Circle section={5} className="right-[122%] top-[49px] bg-sky-04" />

          {/* === Section 6 === */}
          <Circle section={6} className="left-[117%] top-[36px] bg-sky-04" />
          <Circle section={6} className="left-[135%] top-[75px] bg-gold-04" />
          <Circle section={6} className="left-[155%] top-[70px] bg-lush-04" />
          <Circle section={6} className="left-[174%] top-[61px] bg-sky-04" />
          <Circle section={6} className="left-[194%] top-[14px] bg-lush-04" />
          <Circle section={6} className="left-[190%] top-[90px] bg-gold-04" />

          {/* === Section 7 === */}
          <Circle
            section={7}
            className="bottom-[90px] right-[142%] bg-lush-04"
          />
          <Circle
            section={7}
            className="bottom-[41px] right-[159%] bg-amethyst-04"
          />
          <Circle
            section={7}
            className="bottom-[-13px] right-[138%] bg-gold-04"
          />
          {/* <Circle section={7} className="bottom-[12px] right-[115%] bg-amethyst-04" /> */}

          {/* === Section 8 === */}
          <Circle
            section={8}
            className="bottom-[46px] left-[111%] bg-flame-04"
          />
          <Circle section={8} className="bottom-[25px] left-[125%] bg-sky-04" />
          <Circle
            section={8}
            className="bottom-[37px] left-[148%] bg-amethyst-04"
          />
          <Circle
            section={8}
            className="bottom-[-18px] left-[163%] bg-lush-04"
          />
          <Circle
            section={8}
            className="bottom-[43px] left-[172%] bg-flame-04"
          />
          <Circle
            section={8}
            className="bottom-[-10px] left-[187%] bg-lush-04"
          />

          {/* === Section 9 === */}
          <Circle
            section={9}
            className="right-[192%] top-[calc(100%+1px)] bg-flame-04"
          />
          <Circle
            section={9}
            className="right-[160%] top-[calc(100%+32px)] bg-flame-04"
          />
          <Circle
            section={9}
            className="right-[126%] top-[calc(100%+32px)] bg-amethyst-04"
          />
          <Circle
            section={9}
            className="right-[102%] top-[calc(100%+32px)] bg-lush-04"
          />
          <Circle
            section={9}
            className="right-[178%] top-[calc(100%+82px)] bg-gold-04"
          />
          <Circle
            section={9}
            className="right-[140%] top-[calc(100%+95px)] bg-gold-04"
          />
          <Circle
            section={9}
            className="right-[114%] top-[calc(100%+105px)] bg-lush-04"
          />
          <Circle
            section={9}
            className="right-[143%] top-[calc(100%+179px)] bg-gold-04"
          />
          <Circle
            section={9}
            className="right-[118%] top-[calc(100%+207px)] bg-sky-04"
          />

          {/* === Section 10 === */}
          <Circle
            section={10}
            className="left-[6%] top-[calc(100%+43px)] bg-flame-04"
          />
          <Circle
            section={10}
            className="left-[15%] top-[calc(100%+95px)] bg-sky-04"
          />
          <Circle
            section={10}
            className="left-[33%] top-[calc(100%+81px)] bg-flame-04"
          />
          <Circle
            section={10}
            className="left-[0%] top-[calc(100%+120px)] bg-flame-04"
          />
          <Circle
            section={10}
            className="left-[38%] top-[calc(100%+134px)] bg-sky-04"
          />
          <Circle
            section={10}
            className="left-[17%] top-[calc(100%+169px)] bg-lush-04"
          />
          <Circle
            section={10}
            className="left-[4%] top-[calc(100%+198px)] bg-gold-04"
          />
          <Circle
            section={10}
            className="left-[37%] top-[calc(100%+207px)] bg-sky-04"
          />
          <Circle
            section={10}
            className="left-[22%] top-[calc(100%+246px)] bg-gold-04"
          />
          <Circle
            section={10}
            className="left-[35%] top-[calc(100%+281px)] bg-flame-04"
          />
          <Circle
            section={10}
            className="left-[17%] top-[calc(100%+324px)] bg-lush-04"
          />
          <Circle
            section={10}
            className="left-[43%] top-[calc(100%+324px)] bg-gold-04"
          />
          <Circle
            section={10}
            className="left-[33%] top-[calc(100%+352px)] bg-sky-04"
          />

          {/* === Section 11 === */}
          <Circle
            section={11}
            className="right-[14%] top-[calc(100%+58px)] bg-amethyst-04"
          />
          <Circle
            section={11}
            className="right-[-2%] top-[calc(100%+81px)] bg-gold-04"
          />
          <Circle
            section={11}
            className="right-[30%] top-[calc(100%+96px)] bg-sky-04"
          />
          <Circle
            section={11}
            className="right-[6%] top-[calc(100%+134px)] bg-amethyst-04"
          />
          <Circle
            section={11}
            className="right-[40%] top-[calc(100%+159px)] bg-gold-04"
          />
          <Circle
            section={11}
            className="right-[21%] top-[calc(100%+159px)] bg-gold-04"
          />
          <Circle
            section={11}
            className="right-[10%] top-[calc(100%+216px)] bg-gold-04"
          />
          <Circle
            section={11}
            className="right-[29%] top-[calc(100%+237px)] bg-amethyst-04"
          />
          <Circle
            section={11}
            className="right-[42%] top-[calc(100%+261px)] bg-lush-04"
          />
          <Circle
            section={11}
            className="right-[4%] top-[calc(100%+274px)] bg-flame-04"
          />
          <Circle
            section={11}
            className="right-[27%] top-[calc(100%+313px)] bg-gold-04"
          />
          <Circle
            section={11}
            className="right-[14%] top-[calc(100%+317px)] bg-sky-04"
          />
          <Circle
            section={11}
            className="right-[38%] top-[calc(100%+356px)] bg-amethyst-04"
          />
          <Circle
            section={11}
            className="right-[23%] top-[calc(100%+392px)] bg-amethyst-04"
          />
          <Circle
            section={11}
            className="right-[44%] top-[calc(100%+410px)] bg-lush-04"
          />

          {/* === Section 12 === */}
          <Circle
            section={12}
            className="left-[102%] top-[calc(100%+10px)] bg-flame-04"
          />
          <Circle
            section={12}
            className="left-[119%] top-[calc(100%+20px)] bg-amethyst-04"
          />
          <Circle
            section={12}
            className="left-[148%] top-[calc(100%+20px)] bg-sky-04"
          />
          <Circle
            section={12}
            className="left-[117%] top-[calc(100%+104px)] bg-gold-04"
          />
          <Circle
            section={12}
            className="left-[143%] top-[calc(100%+117px)] bg-lush-04"
          />
          <Circle
            section={12}
            className="left-[170%] top-[calc(100%+82px)] bg-gold-04"
          />
          <Circle
            section={12}
            className="left-[195%] top-[calc(100%+81px)] bg-amethyst-04"
          />
          <Circle
            section={12}
            className="left-[102%] top-[calc(100%+188px)] bg-sky-04"
          />
          <Circle
            section={12}
            className="left-[124%] top-[calc(100%+211px)] bg-flame-04"
          />
        </ConfettiContext.Provider>
      </div>

      <div
        className="absolute -bottom-1 left-1/2 z-3 w-full -translate-x-1/2 s:w-800 800:w-1536 2xl:w-full"
        style={{ clipPath: `inset(60% 0 0 0)` }}
      >
        <svg className="w-full" viewBox="0 0 1536 173" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M768.499 0C1064.66 0 1334.26 65.3412 1536 172.301L0 172.831C201.852 65.5572 471.85 0 768.499 0ZM767.5 159C980.958 159 1154 126.765 1154 87C1154 47.2355 980.958 15 767.5 15C554.042 15 381 47.2355 381 87C381 126.765 554.042 159 767.5 159Z"
            className="fill-current"
          />
        </svg>
      </div>
    </div>
  );
}

type CircleProps = {
  className?: string;
  section: number;
  debug?: boolean;
};

function Circle({ className, section }: CircleProps) {
  const { containerRect, contentRect } = useContext(ConfettiContext);
  const [setRef, rect] = useRect({ debounce: 0, ignoreTransform: true });
  const tl = useRef();

  useGSAP(
    () => {
      tl.current = gsap
        .timeline() //
        .to(rect.element, {
          ease: 'none',
          motionPath: {
            path: () =>
              [
                section === 1
                  ? {
                      x: -contentRect.width * 0.1,
                      y: contentRect.top + contentRect.height / 2 - rect.top,
                    }
                  : null,
                section === 2
                  ? {
                      x: -contentRect.width * 0.65,
                      y: contentRect.top + contentRect.height / 2 - rect.top,
                    }
                  : null,
                section === 3
                  ? {
                      x: contentRect.width * 0.65,
                      y: contentRect.top + contentRect.height / 2 - rect.top,
                    }
                  : null,
                section === 4
                  ? {
                      x: contentRect.width * 0.1,
                      y: contentRect.top + contentRect.height / 2 - rect.top,
                    }
                  : null,
                section === 5
                  ? {
                      x: rect.width * 2,
                      y: contentRect.bottom - rect.top,
                    }
                  : null,
                section === 6
                  ? {
                      x: -rect.width * 2,
                      y: contentRect.bottom - rect.top,
                    }
                  : null,
                section === 7
                  ? {
                      x: rect.width * 2,
                      y: contentRect.bottom - rect.top,
                    }
                  : null,
                section === 8
                  ? {
                      x: -rect.width * 2,
                      y: contentRect.bottom - rect.top,
                    }
                  : null,
                {
                  x:
                    containerRect.width / 2 -
                    contentRect.width / 2 +
                    (rect.left / containerRect.width) * contentRect.width -
                    rect.left +
                    rect.width / 2,
                  y: containerRect.bottom - rect.top,
                },
                {
                  x: containerRect.width / 2 - rect.left + rect.width / 2,
                  y: containerRect.bottom,
                },
              ]
                .flat()
                .filter(Boolean),
            curviness: 2,
          },
          scrollTrigger: {
            trigger: containerRect.element,
            start: 'top 100px',
            end: 'bottom 100px',
            scrub: 1,
            invalidateOnRefresh: true,
          },
        });
    },
    {
      revertOnUpdate: true,
      dependencies: [
        rect.top,
        rect.left,
        rect.width,
        containerRect.bottom,
        containerRect.width,
        contentRect.top,
        contentRect.bottom,
        contentRect.width,
        contentRect.height,
      ],
    },
  );

  return (
    <div
      ref={setRef}
      className={cn(
        'absolute h-16 w-16 rounded-full will-change-transform',
        className,
      )}
    ></div>
  );
}
