import type { SanityStats } from '#/lib/sanity/queries/sections';

import { Headline } from './Headline';

export function Stats(props: SanityStats) {
  return (
    <div className="p-main mx-auto my-100 max-w-[1235px] text-center">
      <Headline className="mb-40" value={props.headline} />
      <div className="flex flex-col gap-15 m:flex-row l:gap-25">
        {props.stats.map((stat, i) => (
          <div
            key={stat._key}
            className="stat flex-1 space-y-15 rounded-15 px-20 pb-50 pt-50 m:rounded-25 l:space-y-25 l:px-40 l:pb-60"
            style={{
              background: props.statBackground ?? undefined,
              color: props.statTextColor ?? undefined,
              transitionDelay: `${0.3 + i * 0.1}s`,
            }}
          >
            <div className="text-65 font-[440] leading-105 -tracking-1 l:text-82">
              {stat.value}
            </div>
            <div className="font-azeret text-12 uppercase leading-150 tracking-5">
              {stat.caption}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
