import type { SanityCallToAction } from '#/lib/sanity/queries/sections';

import { Button } from './Button';
import { Headline } from './Headline';
import { Media } from './Media';

export function CallToAction(props: SanityCallToAction) {
  return (
    <div
      className="p-main rounded-t-20 pb-100 pt-65 text-center"
      style={{
        background: props.background ?? undefined,
        color: props.foreground ?? undefined,
      }}
    >
      <div className="mx-auto max-w-735">
        {props.media ? (
          <Media
            className="mx-auto mb-25 w-118"
            media={props.media}
            sizes="118px"
          />
        ) : null}
        <Headline
          value={props.headline}
          variant={3}
          className="mb-12 m:mb-20"
        />
        {props.bodyCopy ? (
          <p
            className="text-body mx-auto max-w-[375px]"
            style={{ color: props.foreground ?? undefined }}
          >
            {props.bodyCopy}
          </p>
        ) : null}
        <Button
          href={props.cta.href}
          target={props.cta.target}
          className="mt-25 m:mt-30"
          variant="primary"
        >
          {props.cta.title}
        </Button>
      </div>
    </div>
  );
}
