import type { TranscendUserTraits } from '../types';
import { cleanEmail } from './clean-email';
import { emailToUserId } from './user-id';

export async function formatIdentify({
  email,
  traits,
}: {
  email: string;
  traits?: Record<string, string | null> | undefined;
}): Promise<{ formattedUserId: string; formattedTraits: TranscendUserTraits }> {
  // Clean the email
  const formattedEmail = cleanEmail(email);

  // SHA-256 of email
  const formattedUserId = await emailToUserId(formattedEmail);

  // Format the traits object
  const formattedTraits: TranscendUserTraits = {
    ...traits,
    email: formattedEmail,
  };

  return { formattedUserId, formattedTraits };
}
