import type { SanityPageSection } from '#/lib/sanity/queries/sections';
import { PageSections } from '#/components/PageSections';

type Props = {
  sections?: SanityPageSection[] | null;
};

export function Page({ sections }: Props) {
  return (
    <div className="min-h-screen">
      <PageSections sections={sections ?? []} />
    </div>
  );
}
