import type { SanityPlatformOverview } from '#/lib/sanity/queries/sections';
import { useAppSizes } from '#/lib/use-app-sizes';

import { Headline } from './Headline';
import { Media } from './Media';

export function PlatformOverview(props: SanityPlatformOverview) {
  const { headerHeight } = useAppSizes();

  return (
    <div className="p-main">
      <div className="mx-auto mb-55 max-w-[480px] space-y-12 text-center m:space-y-25">
        <Headline value={props.headline} />
        <p className="text-body">{props.bodyCopy}</p>
      </div>
      <div className="-mx-12 flex flex-wrap justify-center gap-y-24">
        {props.cards?.map((card, i) => (
          <div
            key={card._key}
            className="sticky min-h-[250px] flex-wrap px-12 m:static m:w-1/2 min-[1200px]:flex-1"
            style={{ top: headerHeight + 8 + i * 25 }}
          >
            <div className="group relative h-full overflow-clip rounded-15 border-1 border-grayscale-03 bg-grayscale-01 p-25 transition hover:border-charcoal">
              <div
                className="absolute inset-0 opacity-0 transition group-hover:opacity-100"
                style={{ background: card.background }}
              ></div>
              <div className="relative flex h-full flex-col justify-start space-y-20">
                {card.icon ? (
                  <Media
                    media={card.icon}
                    style={{ width: card.icon.width }}
                    sizes={`${card.icon.width}px`}
                  />
                ) : null}
                <div className="space-y-10 flex flex-col justify-between flex-1">
                  <h3 className="text-22 font-[440] leading-110 -tracking-1">
                    {card.title}
                  </h3>
                  <p className="text-14 font-light leading-150 text-grayscale-06">
                    {card.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
