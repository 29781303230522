import { z } from 'zod';

// Atoms
export const Company = z
  .object({
    name: z.string(),
  })
  .partial();
export const PardotCampaignId = z.number().nonnegative();
export const PardotListIds = z.array(z.string().min(1)).min(1);
export const BotScore = z.number().nonnegative();
export const Email = z.string().email();

// Molecules
export const ContactFormTrackingEventProperties = z
  .object({
    firstName: z.string(),
    lastName: z.string(),
    title: z.string(),
    email: Email,
    phone: z.string(),
    botScore: BotScore.optional(),
    company: Company.optional(),
    message: z.string(),
    pardotCampaignId: PardotCampaignId,
    pardotListIds: PardotListIds,
  })
  .partial()
  .optional();
