import { useEffect, useId } from 'react';
import { useRect } from '@darkroom.engineering/hamo';

import { useNavTheme } from '#/lib/nav-theme';
import type { SanityFooter } from '#/lib/sanity/queries/footer';
import { useAppSizes } from '#/lib/use-app-sizes';
import { cn } from '#/lib/utils';
import { BasicText } from '#/components/BasicText';
import { Link } from '#/components/Link';
import { TranscendLogo } from '#/components/TranscendLogo';

import { FormEmailCapture } from './FormEmailCapture';
import { YourPrivacyChoices } from './YourPrivacyChoices';

export function SiteFooter({
  newsletter,
  recaptchaNotice,
  linkGroups,
  secondaryLinks,
  copyrightText,
  location,
  socialLinks,
}: SanityFooter) {
  const formattedCopyright = copyrightText.replaceAll(
    '{year}',
    String(new Date().getFullYear()),
  );

  const { registerSection, unregisterSection } = useNavTheme();
  const { headerHeight } = useAppSizes();
  // @ts-ignore
  const [setRef, rect] = useRect({ debounce: 0 });
  const id = useId();
  const sectionId = `footer-${id}`;

  useEffect(() => {
    const top = rect.top - headerHeight / 2;
    const bottom = rect.bottom - headerHeight / 2;
    if (!isNaN(top) && !isNaN(bottom)) {
      registerSection(sectionId, top, bottom);
    }
    // eslint-disable-next-line
  }, [rect, headerHeight]);

  useEffect(() => {
    return () => {
      unregisterSection(sectionId);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <footer
      ref={setRef}
      className="w-full grid-cols-12 gap-x-20 bg-charcoal px-15 pb-40 pt-50 text-cloud m:grid m:pb-45 m:pt-60 l:px-25"
    >
      <div className="col-span-6 pl-22 pr-22 m:pr-45 l:col-span-4 l:pl-45 l:pr-0 xl:col-span-3">
        <div className="mb-20 space-y-10 m:mb-30 m:space-y-20">
          <h2 className="text-22 font-[440] leading-116 -tracking-1 text-cloud m:text-28">
            {newsletter.headline}
          </h2>
          {newsletter?.bodyCopy ? (
            <p className="text-14 font-light leading-150 text-grayscale-03 m:text-16">
              {newsletter.bodyCopy}
            </p>
          ) : null}
        </div>
        <FormEmailCapture
          emailInputPlaceholder={newsletter.emailInputPlaceholder}
          submitButtonLabel={newsletter.submitButtonLabel}
          trackingEventName={newsletter.trackingEventName}
          pardotCampaignId={newsletter.pardotCampaignId}
          pardotListIds={newsletter.pardotListIds}
          confirmation={newsletter.confirmation}
          variant="footer"
        />
        <div className="text-legal my-15 text-grayscale-04 m:my-20 [&_a:hover]:text-cloud">
          {newsletter.legalText ? (
            <BasicText value={newsletter.legalText} />
          ) : null}
          <hr className="mb-24 mt-48 border-grayscale-06" />
          {recaptchaNotice ? <BasicText value={recaptchaNotice} /> : null}
          <YourPrivacyChoices />
        </div>
      </div>

      <div className="col-span-6 col-start-7 mt-40 columns-2 gap-x-[8%] pl-22 text-13 leading-150 -tracking-1 text-cloud m:mt-0 m:pl-0 m:text-14 l:col-span-7 l:col-start-6 l:columns-3 l:gap-x-[10%] xl:col-span-8 xl:col-start-5 xl:gap-x-[15%]">
        {linkGroups.map((group) => {
          return (
            <div key={group._key} className="break-inside-avoid pb-36 m:pb-40">
              <h3 className="mb-16 font-[440] m:mb-18">{group.title}</h3>
              <ul className="space-y-14 font-light text-grayscale-03 m:space-y-16">
                {group.links.map((link) => (
                  <li key={link._key}>
                    <Link
                      className="transition-colors hover:text-cloud"
                      href={link.href}
                      target={link.target}
                    >
                      {link.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
        <SecondaryLinks
          className="space-y-15 m:hidden"
          secondaryLinks={secondaryLinks}
        />
      </div>
      <div className="relative col-span-12 mt-85 flex h-30 items-center justify-between px-22 l:px-45">
        <SecondaryLinks
          className="hidden gap-x-20 text-14 m:flex l:gap-x-35"
          secondaryLinks={secondaryLinks}
        />
        <Link
          className="block h-full w-124 flex-shrink-0 text-cloud m:absolute m:left-1/2 m:-translate-x-1/2"
          href="/"
        >
          <TranscendLogo className="w-full" />
        </Link>

        <ul className="flex gap-x-14">
          {socialLinks.map((socialLink) => {
            return (
              <li key={socialLink._key}>
                <Link
                  className="block h-24 w-24"
                  href={socialLink.href}
                  target="_blank"
                >
                  {socialLink.icon.alt ? (
                    <span className="sr-only">{socialLink.icon.alt}</span>
                  ) : null}
                  <div
                    className="h-full w-full bg-grayscale-03 transition-colors hover:bg-white"
                    style={{
                      maskSize: 'contain',
                      WebkitMaskRepeat: 'no-repeat',
                      maskRepeat: 'no-repeat',
                      maskPosition: 'center',
                      WebkitMaskImage: `url(${socialLink.icon.url})`,
                      maskImage: `url(${socialLink.icon.url})`,
                    }}
                  ></div>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="col-span-12 mx-auto mt-20 max-w-[227px] text-center text-12 font-light leading-150 text-grayscale-04 m:mx-0 m:max-w-none">
        {formattedCopyright}
        <>
          <span className="hidden px-5 m:inline">•</span>
          <br className="m:hidden" />
        </>
        {location}
      </div>
    </footer>
  );
}

function SecondaryLinks({
  className,
  secondaryLinks,
}: {
  className?: string;
  secondaryLinks: SanityFooter['secondaryLinks'];
}) {
  return (
    <ul
      className={cn(className, 'font-[440] leading-100 -tracking-1 text-cloud')}
    >
      {secondaryLinks.map((link) => (
        <li key={link._key}>
          <Link
            className="transition-colors hover:text-grayscale-03"
            href={link.href ?? '#'}
            target={link.target}
          >
            {link.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}
