import { useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';

import type { SanitySolutionsTabs } from '#/lib/sanity/queries/sections';
import { cn, COLORS } from '#/lib/utils';
import { BasicText } from '#/components/BasicText';
import { Button } from '#/components/Button';
import { Headline } from '#/components/Headline';
import { Media } from '#/components/Media';

export function SolutionsTabs(props: SanitySolutionsTabs) {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [selectedScrollSnap, setSelectedScrollSnap] = useState(0);

  useEffect(() => {
    if (emblaApi) {
      const handleSelect = () => {
        setSelectedScrollSnap(emblaApi.selectedScrollSnap());
      };

      emblaApi.on('select', handleSelect);

      return () => {
        emblaApi.off('select', handleSelect);
      };
    }
  }, [emblaApi]);

  return (
    <div className="p-main overflow-hidden">
      <div className="mx-auto w-full max-w-1235">
        {props.headline ? (
          <Headline
            className="mx-auto mb-40 w-full max-w-735 text-center"
            value={props.headline}
            variant={3}
          />
        ) : null}
        <div className="no-scrollbar -mx-15 mb-25 space-x-10 overflow-x-auto whitespace-nowrap px-15 text-center">
          {props.tabs.map((tab, tabIndex) => (
            <button
              key={tab._key}
              className={cn(
                'rounded-10 bg-grayscale-01 px-16 py-13 text-14 font-[440] leading-100 transition',
                {
                  'bg-[var(--tab-theme-color)]':
                    tabIndex === selectedScrollSnap,
                },
              )}
              style={
                {
                  '--tab-theme-color': COLORS[tab.colorTheme]['01'],
                } as React.CSSProperties
              }
              onClick={() => emblaApi?.scrollTo(tabIndex)}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <div ref={emblaRef}>
          <div className="flex gap-x-15">
            {props.tabs.map((tab, tabIndex) => (
              <div
                key={tab._key}
                className={cn('min-w-full transition-opacity', {
                  'opacity-0': tabIndex !== selectedScrollSnap,
                })}
              >
                <div
                  className="flex flex-col rounded-15 [box-shadow:inset_0_0_0_1px_theme(colors.charcoal)] m:flex-row m:rounded-25"
                  style={{ background: COLORS[tab.colorTheme]['02'] }}
                >
                  <div className="p-30 pb-45 m:w-1/2 l:p-64 l:pb-90">
                    <div className="w-full max-w-415">
                      <Headline value={tab.headline} variant={5} />
                      <BasicText
                        value={tab.content}
                        className="mt-10 text-pretty l:mt-25"
                      />
                      {tab.link?.href ? (
                        <Button
                          href={tab.link.href}
                          variant="link"
                          className="mt-25"
                        >
                          {tab.link.title}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className={cn(
                      'overflow-clip rounded-15 border-1 border-charcoal m:w-1/2 m:rounded-25',
                      {
                        'p-45': tab.hasMediaPadding,
                      },
                    )}
                    style={{ background: COLORS[tab.colorTheme]['05'] }}
                  >
                    <Media
                      className="size-full"
                      media={tab.media}
                      placeholder={!tab.hasMediaPadding}
                      sizes={[
                        '100vw',
                        {
                          m: '50vw',
                          '1285px': '615px',
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
