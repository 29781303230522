import type { CSSProperties } from 'react';
import { CssPixels } from '@repo/shared/types';
import { z } from 'zod';

import { SCREENS } from '#/lib/utils';

const CSSProperties: z.ZodType<CSSProperties> = z
  .object({
    height: z.string().optional(),
    width: z.string().optional(),
    margin: z.string().optional(),
    padding: z.string().optional(),
    borderRadius: z.string().optional(),
    backgroundColor: z.string().optional(),
    color: z.string().optional(),
    display: z.string().optional(),
    // etc... (use passthrough to allow any property)
  })
  .passthrough();

export const MediaCondition = z.record(
  z.union([
    z.enum(
      Object.keys(SCREENS) as [
        keyof typeof SCREENS,
        ...(keyof typeof SCREENS)[],
      ],
    ),
    CssPixels,
  ]),
  z.string(),
);
export type MediaCondition = z.infer<typeof MediaCondition>;

export const MediaProps = z.object({
  className: z.string().optional().nullable(),
  mediaClassName: z.string().optional().nullable(),
  aspect: z.boolean().optional().nullable(),
  placeholder: z.boolean().optional().nullable(),
  preload: z.boolean().optional().nullable(),
  sizes: z.union([
    z.string(),
    MediaCondition,
    z.array(z.union([z.string(), MediaCondition])),
  ]),
  style: CSSProperties.optional().nullable(),
});
export type MediaProps = z.infer<typeof MediaProps>;
