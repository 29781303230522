import { useEffect, useMemo, useState } from 'react';
import AutoHeight from 'embla-carousel-auto-height';
import Fade from 'embla-carousel-fade';
import useEmblaCarousel from 'embla-carousel-react';
import gsap from 'gsap';

import type { SanityTestimonialCarousel } from '#/lib/sanity/queries/sections';
import { useAppSizes } from '#/lib/use-app-sizes';
import { cn } from '#/lib/utils';

import { ArrowLeft } from './ArrowLeft';
import { ArrowRight } from './ArrowRight';
import { Headline } from './Headline';
import { Media } from './Media';

export function TestimonialCarousel({
  headline,
  testimonials,
}: SanityTestimonialCarousel) {
  const backgroundClassnameByIndex = gsap.utils.wrap([
    'bg-gold-02',
    'bg-lush-02',
    'bg-apple-02',
    'bg-sky-02',
    'bg-amethyst-02',
    'bg-flame-02',
  ]);

  const options = { loop: true };
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    AutoHeight(),
    Fade(),
  ]);
  const [selectedScrollSnap, setSelectedScrollSnap] = useState(0);
  const { windowWidth } = useAppSizes();

  useEffect(() => {
    if (emblaApi) {
      if (windowWidth < 768) {
        emblaApi.reInit(options, [AutoHeight(), Fade()]);
      } else {
        emblaApi.reInit(options, [Fade()]);
      }
    }
  }, [windowWidth]);

  useEffect(() => {
    if (emblaApi) {
      const handleSelect = () => {
        setSelectedScrollSnap(emblaApi.selectedScrollSnap());
      };

      emblaApi.on('select', handleSelect);

      return () => {
        emblaApi.off('select', handleSelect);
      };
    }
  }, [emblaApi]);

  const offset = useMemo(() => (windowWidth < 768 ? 8 : 10), [windowWidth]);

  return (
    <div className="p-main overflow-hidden">
      <div className="mx-auto w-full max-w-980">
        <Headline
          value={headline}
          className="mx-auto mb-26 w-full max-w-735 text-balance text-center m:mb-40"
        />
        <div
          style={{
            paddingBottom: `${testimonials.length * offset}px`,
          }}
        >
          <div className="relative">
            {testimonials.map((_, index) => (
              <div
                key={index}
                className="absolute inset-0 size-full rounded-15 border-1 border-charcoal bg-grayscale-01 m:rounded-25"
                style={{
                  transform: `translate(
                  ${testimonials.length * offset - index * offset}px,
                  ${testimonials.length * offset - index * offset}px
                )`,
                }}
              ></div>
            ))}
            <div
              className={cn(
                'relative z-1 overflow-hidden rounded-15 border-1 border-charcoal m:rounded-25',
                backgroundClassnameByIndex(selectedScrollSnap),
              )}
              ref={emblaRef}
            >
              <div className="flex cursor-grab items-start active:cursor-grabbing m:items-stretch">
                {testimonials.map((testimonial, testimonialIndex) => {
                  const ueUrl = testimonial?.ueUrl
                    ? new URL(testimonial?.ueUrl)
                    : null;

                  return (
                    <div
                      key={testimonial._key}
                      className={cn(
                        'min-w-full select-none px-30 pb-50 pt-30 m:px-60 m:pb-40 m:pt-50',
                        backgroundClassnameByIndex(testimonialIndex),
                      )}
                    >
                      <div className="flex h-full max-w-830 flex-col">
                        <p className="relative mb-30 text-pretty text-20 font-[440] leading-137 -tracking-2 before:absolute before:right-full before:content-['“'] after:content-['”'] s:text-22 m:mb-35 m:text-28">
                          {testimonial.quote}
                        </p>
                        <div className="mt-auto flex flex-wrap items-center gap-15 m:gap-30">
                          {testimonial.image?.url ? (
                            <Media
                              media={testimonial.image}
                              className="h-auto w-85 object-contain m:w-140"
                              mediaClassName="object-contain"
                              sizes={['85px', { m: '100px' }]}
                            />
                          ) : null}
                          <div className="space-y-7">
                            <p className="text-pretty text-16 font-[440] leading-116 tracking-0 text-grayscale-06">
                              {testimonial.name}
                            </p>
                            {testimonial.role ? (
                              <p className="text-pretty text-14 font-[440] leading-116 tracking-0 text-grayscale-05">
                                {testimonial.role}
                              </p>
                            ) : null}
                          </div>
                          {ueUrl ? (
                            <a
                              className="text-grayscale-06 m:ml-auto w-full m:w-auto"
                              href={ueUrl.toString()}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="flex items-center gap-x-10 font-medium mb-6">
                                <svg
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19 16L26 0H21L15.8667 11.7333L14 8H9L13 16H19Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M16 27C22.0751 27 27 22.0751 27 16H32C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16H5C5 22.0751 9.92487 27 16 27Z"
                                    fill="currentColor"
                                  />
                                </svg>
                                <span className="font-medium text-pretty text-16 leading-116 tracking-0">
                                  UE Verified
                                </span>
                              </div>

                              {ueUrl?.hostname + ueUrl?.pathname}
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <nav className="mt-20 flex items-center justify-center gap-x-20 m:mt-40">
          <button onClick={() => emblaApi?.scrollPrev()}>
            <ArrowLeft className="w-15" />
          </button>
          <div className="flex gap-x-10">
            {testimonials.map((_, index) => (
              <button
                className={cn(
                  'h-23 w-23 rounded-full border-1 border-charcoal',
                  index === selectedScrollSnap
                    ? backgroundClassnameByIndex(index)
                    : null,
                )}
                key={index}
                onClick={() => emblaApi?.scrollTo(index)}
              ></button>
            ))}
          </div>
          <button onClick={() => emblaApi?.scrollNext()}>
            <ArrowRight className="w-15" />
          </button>
        </nav>
      </div>
    </div>
  );
}
