import { PortableText, type PortableTextBlock } from 'next-sanity';

import { Link } from '#/components/Link';

type Props = {
  value: PortableTextBlock[];
  className?: string;
};

export function BasicText({ value, className }: Props) {
  return (
    <div className={className}>
      <PortableText
        value={value}
        components={{
          marks: {
            internalLink: ({ value, children }) => {
              return (
                <Link href={value.href} target={value.target}>
                  {children}
                </Link>
              );
            },
            externalLink: ({ value, children }) => {
              return (
                <Link href={value.href} target={value.target}>
                  {children}
                </Link>
              );
            },
          },
        }}
      />
    </div>
  );
}
