import React, { useEffect, useState, type MouseEventHandler } from 'react';
import Link from 'next/link';
import { TrackingConsent, type AirgapAPI } from '@transcend-io/airgap.js-types';

import { useConsentManager } from '#/lib/consent-manager';
import { formatWithOxfordComma } from '#/lib/utils';

import { Button } from './Button';
import { TranscendLogo } from './TranscendLogo';

export interface FallbackProps {
  /** A set of consent purposes that are required but missing for the `urlsRequiredForRender` */
  missingConsentPurposes: Set<string>;
  /** The onClick event if the user opts in, which sets airgap.setConsent(event.nativeEvent, targetTrackingConsent) */
  onConsentGiven: MouseEventHandler;
}

/**
 * For a given set of URLs, get the purposes that are missing consent to connect to those URLs.
 */
async function getMissingConsentPurposesForUrls(
  airgap: AirgapAPI,
  urls: string[],
): Promise<FallbackProps['missingConsentPurposes']> {
  const currentTrackingConsent = airgap.getConsent().purposes;

  // A set of missing consent purposes for the current user.
  const missingConsentPurposes = new Set<string>();
  await Promise.all(
    urls.map(async (url) => {
      const requestIsAllowed: boolean = await airgap.isAllowed(url);
      if (requestIsAllowed) return;

      const purposesRequired: Set<string> = await airgap.getPurposes(url);

      // The purposes that are required but missing for the current user
      const purposesMissing = [...purposesRequired].filter(
        (purpose) => !currentTrackingConsent[purpose],
      );

      // Special case: If the purpose is just Unknown, and the request is blocked
      if (purposesMissing.includes('Unknown')) {
        // Require consent for all purposes in the regime
        const allPurposes = airgap.getRegimePurposes();
        for (const purpose of allPurposes) {
          if (!currentTrackingConsent[purpose]) {
            missingConsentPurposes.add(purpose);
          }
        }
      } else {
        // Add the missing purposes to the set
        for (const purpose of purposesMissing) {
          missingConsentPurposes.add(purpose);
        }
      }
    }),
  );

  return missingConsentPurposes;
}

/**
 * A fallback component to render when the user's consent preferences prevent rendering the wrapped component.
 */
const DefaultFallback: React.FC<FallbackProps> = ({
  missingConsentPurposes,
  onConsentGiven,
}) => {
  const formattedMissingPurposes = formatWithOxfordComma(
    [...missingConsentPurposes].map((purpose) => purpose.toLowerCase()),
    'or',
  );

  return (
    <div className="flex w-full h-full items-center justify-center flex-col gap-x-10 m:gap-x-15 p-24 m:p-75 text-center">
      <p className="text-16 font-[440] leading-116 text-grayscale-06 m:text-18">
        This content requires your consent to load.
      </p>
      <p className="text-12 m:text-16">
        The technology that displays this content may collect data
        {formattedMissingPurposes.length > 0
          ? ` for ${formattedMissingPurposes} purposes`
          : ' from your device'}
        .
      </p>

      <Button
        className="my-12 m:my-24"
        variant="secondary"
        onClick={onConsentGiven}
      >
        Opt in
      </Button>

      <span className="text-12 m:text-14 text-grayscale-05 inline">
        Powered by{' '}
        <Link
          href="/platform/consent-management"
          target="_blank"
          className="hyperlink"
        >
          Transcend Consent Management
        </Link>
      </span>
      <TranscendLogo iconOnly className="w-18 mt-10 hidden m:block" />
    </div>
  );
};

/**
 * If the user has not consented to URLs that would break rendering the children, render a fallback component.
 */
export const ConsentBoundary: React.FC<{
  /** The children are rendered when `urlsRequiredForRender` satisfy the user's consent preferences. */
  children: React.ReactNode;
  /** The URLs that would break rendering of the `children`. */
  urlsRequiredForRender: string[];
  /**
   * A callback function which renders a fallback component.
   * This is rendered when the `urlsRequiredForRender` do not satisfy the user's consent preferences.
   * The callback passes information about the missing consent purposes, should an opt-in button be rendered in this fallback.
   * Defaults to a basic fallback message with an opt-in button.
   */
  fallback?: (fallbackProps: FallbackProps) => React.ReactNode;
}> = ({
  children,
  urlsRequiredForRender,
  fallback = (fallbackProps) => {
    return <DefaultFallback {...fallbackProps} />;
  },
}) => {
  const { airgap } = useConsentManager();

  // A list of consent purposes that must to be opted into for the children to render
  const [missingConsentPurposes, setMissingConsentPurposes] = useState<
    FallbackProps['missingConsentPurposes']
  >(new Set());

  // Set the missing consent purposes for the URLs required for rendering
  useEffect(() => {
    if (airgap) {
      getMissingConsentPurposesForUrls(airgap, urlsRequiredForRender).then(
        setMissingConsentPurposes,
      );
    }
  }, [airgap, urlsRequiredForRender]);

  // Whether to show the fallback component
  const [showFallback, setShowFallback] = useState(false);

  // Show the fallback component if there are missing consent purposes
  useEffect(() => {
    if (missingConsentPurposes.size > 0) {
      setShowFallback(true);
    }
  }, [missingConsentPurposes]);

  /**
   * Event handler for the fallback component to call when the user clicks the "Opt in" button via `<button onClick={onConsentGiven}>`
   * Triggers re-render of ConsentBoundary
   */
  const onConsentGiven: MouseEventHandler = async (event) => {
    if (!airgap) {
      throw new Error(
        'Airgap.js is not available! Is `onConsentGiven` called outside the fallback component?',
      );
    }

    // The object to pass to airgap.setConsent(event.nativeEvent, targetTrackingConsent), if the user opts in
    const targetTrackingConsent: TrackingConsent = airgap.getConsent().purposes;
    for (const purpose of missingConsentPurposes) {
      targetTrackingConsent[purpose] = true;
    }

    // Set consent and re-render the children
    await airgap.setConsent(event.nativeEvent, targetTrackingConsent);
    setShowFallback(false);
  };

  if (showFallback) {
    // Render the fallback component by calling the implementor-supplied callback
    return (
      <>
        {fallback({ missingConsentPurposes, onConsentGiven })}
        <div hidden>{children}</div>
      </>
    );
  }

  return children;
};
