import type { SanityTestimonialSection } from '#/lib/sanity/queries/sections';
import { Button } from '#/components/Button';

import { Media } from './Media';

export function TestimonialSection(props: SanityTestimonialSection) {
  return (
    <div className="p-main to-cipher-01.0 bg-gradient-to-b from-cipher-01/0 via-cipher-01 pb-95 pt-85 m:pb-60 m:pt-120">
      <div className="mx-auto mb-30 max-w-[856px] text-left text-26 font-[440] leading-136 -tracking-1 m:mb-35 m:text-center m:text-34">
        “{props.quote}”
      </div>
      <div className="flex flex-col items-start justify-start gap-x-20 m:flex-row m:items-center m:justify-center m:gap-x-28">
        {props?.image ? (
          <Media
            media={props.image}
            className="order-last mt-25 h-28 s:h-32 m:order-first m:mt-0 m:h-48"
            mediaClassName="object-contain shrink"
            sizes={[
              `${(28 * props.image.width) / props.image.height}px`,
              {
                m: `${(32 * props.image.width) / props.image.height}px`,
                l: `${(48 * props.image.width) / props.image.height}px`,
              },
            ]}
          />
        ) : null}
        <div className="space-y-3 m:space-y-5">
          <p className="text-14 font-[440] leading-116 m:text-18">
            {props.name}
          </p>
          {props?.role ? (
            <p className="text-12 font-[440] leading-116 text-grayscale-04 m:text-14">
              {props.role}
            </p>
          ) : null}
        </div>
      </div>
      {props.cta ? (
        <div className="mt-40 flex justify-start s:justify-center">
          <Button
            href={props.cta.href}
            target={props.cta.target}
            variant="secondary"
          >
            {props.cta.title}
          </Button>
        </div>
      ) : null}
    </div>
  );
}
