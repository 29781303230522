import { forwardRef, type Ref } from 'react';

import { cn } from '#/lib/utils';

import { Overline } from './Overline';

/**
 * Headline variants -> desktop font sizes
 * 1. 82
 * 2. 64
 * 3. 50
 * 4. 42
 * 5. 34
 * 6. 28
 */

type Props = {
  value: string;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
  overline?: string | null;
  className?: string;
  variant?: 1 | 2 | 3 | 4 | 5 | 6;
};

export const Headline = forwardRef(
  (
    { value, as: Tag = 'h2', overline, className, variant = 4 }: Props,
    ref: Ref<HTMLHeadingElement>,
  ) => {
    // Function to split the value into an array of strings and JSX elements
    const processValue = () => {
      // Temporary array to store the processed segments
      const segments = [];
      // Splitting by curly brackets, keeping them in the result for identification
      const parts = value.split(/(\{|\})/);
      let isCurlyText = false; // Flag to track whether we're inside curly brackets

      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        if (part === '{') {
          isCurlyText = true;
        } else if (part === '}') {
          isCurlyText = false;
        } else if (isCurlyText) {
          // Wrap text inside curly brackets with a span and the desired class
          segments.push(
            <span key={i} className="slant">
              {part}
            </span>,
          );
        } else {
          // Push regular text directly
          segments.push(part);
        }
      }
      return segments;
    };

    // Process the value and store the segments
    const content = processValue();

    return (
      <Tag
        ref={ref}
        className={cn(
          'text-balance font-[440] leading-105 -tracking-1',
          {
            'text-42 m:text-70 l:text-82': variant === 1,
            'text-36 m:text-56 l:text-64': variant === 2,
            'text-36 m:text-43 l:text-50': variant === 3,
            'text-28 m:text-35 l:text-42': variant === 4,
            'text-26 m:text-30 l:text-34': variant === 5,
            'text-22 m:text-25 l:text-28': variant === 6,
          },
          className,
        )}
      >
        {overline ? <Overline className="mb-15">{overline}</Overline> : null}
        {content}
      </Tag>
    );
  },
);

Headline.displayName = 'Headline';
