import type { SanityEditorialCollage } from '#/lib/sanity/queries/sections';

import { BasicText } from './BasicText';
import { Button } from './Button';
import { Headline } from './Headline';
import { Media } from './Media';

export function EditorialCollage(props: SanityEditorialCollage) {
  const [firstAsset, secondAsset] = props.media;

  return (
    <div className="p-main">
      <div className="mx-auto max-w-980 space-y-32 m:space-y-56">
        <div className="flex flex-col gap-32 m:flex-row m:items-center m:gap-56">
          <div className="w-3/4 overflow-clip rounded-15 m:w-1/2 m:rounded-25 l:w-3/5">
            <Media
              media={firstAsset}
              placeholder={true}
              sizes={['75vw', { m: '50vw', l: '588px' }]}
            />
          </div>
          <div className="w-[84%] m:w-1/2 l:w-2/5">
            <Headline
              className="mb-15 m:mb-25"
              value={props.headline}
              overline={props.overline}
              variant={5}
            />
            <BasicText value={props.bodyCopy} />
            {props.cta ? (
              <Button
                className="mt-25 m:mt-35"
                href={props.cta.href}
                target={props.cta.target}
                variant="secondary"
              >
                {props.cta.title}
              </Button>
            ) : null}
          </div>
        </div>
        <div className="ml-auto w-3/4 max-w-735 m:w-full">
          <div className="overflow-clip rounded-15 m:rounded-25">
            <Media
              media={secondAsset}
              placeholder={true}
              sizes={['75vw', { m: '735px' }]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
