import type { PortableTextBlock } from 'next-sanity';
import slugify from 'slugify';

import type { SanityDocumentTemplate } from '#/lib/sanity/queries/sections';
import { cn } from '#/lib/utils';
import { Link } from '#/components/Link';

import { Headline } from './Headline';
import { RichText } from './RichText';

export function DocumentTemplate(props: SanityDocumentTemplate) {
  const toc = generateTableOfContents(props.content, props.toc.excludedStrings);
  const tocComponent = (
    <ul>
      {toc.map((item, index) => (
        <li
          key={index}
          className="mb-15 text-14 font-[440] leading-120 -tracking-1 text-grayscale-04"
        >
          {item.isHeading ? (
            <h4
              className={cn(
                'mb-15 font-azeret text-12 font-normal uppercase leading-150 tracking-5',
                {
                  'mt-45': index !== 0,
                },
              )}
            >
              {item.text}
            </h4>
          ) : (
            <>
              <Link
                className="transition duration-200 hover:text-charcoal"
                href={item.slug}
              >
                {item.text}
              </Link>
              {item.children.length > 0 && (
                <ul className="pl-15">
                  {item.children.map((child) => (
                    <li key={child.slug} className="mt-15">
                      <Link
                        className="transition duration-200 hover:text-charcoal"
                        href={child.slug}
                      >
                        {child.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </li>
      ))}
    </ul>
  );

  return (
    <div className="p-main mb-100 pt-45">
      <div className="mx-auto grid max-w-1235 grid-cols-10 gap-x-20">
        <div className="col-span-3 hidden l:block">
          <div className="max-w-235 pt-10">{tocComponent}</div>
        </div>
        <div className="col-span-full l:col-span-7">
          <Headline value={props.pageTitle} variant={2} />
          <details className="mt-45 rounded-10 border-1 border-grayscale-02 l:hidden">
            <summary className="cursor-pointer select-none p-15 font-azeret text-12 font-normal uppercase leading-150 tracking-5 text-grayscale-05">
              Table of Contents
            </summary>
            <div className="p-15">{tocComponent}</div>
          </details>
          <RichText
            value={props.content}
            excludedStrings={props.toc.excludedStrings}
          />
        </div>
      </div>
    </div>
  );
}

type TOCItem = TOCLink | TOCHeading;

type TOCLink = {
  text: string;
  slug: string;
  children: TOCChild[];
  isHeading: false;
};

type TOCHeading = {
  text: string;
  children: TOCChild[];
  isHeading: true;
};

type TOCChild = {
  text: string;
  slug: string;
};

function generateTableOfContents(
  content: PortableTextBlock[],
  excludedStrings: string[],
): TOCItem[] {
  const toc: TOCItem[] = [];
  let currentItem: TOCItem | null = null;

  const removeExcludedStrings = (text: string): string => {
    excludedStrings.forEach((excluded) => {
      text = text.replace(new RegExp(excluded, 'g'), '');
    });
    return text.trim();
  };

  content.forEach((block) => {
    if (block.style === 'h2') {
      let text = block.children.map((child) => child.text).join('');
      text = removeExcludedStrings(text);
      const slug = `#${slugify(text, { strict: true, lower: true })}`;
      currentItem = { text, slug, children: [], isHeading: false };
      toc.push(currentItem);
    } else if (block.style === 'h3' && currentItem) {
      let text = block.children.map((child) => child.text).join('');
      text = removeExcludedStrings(text);
      const slug = `#${slugify(text, { strict: true, lower: true })}`;
      currentItem.children.push({ text, slug });
    } else if (block.style === 'tocHeading') {
      let text = block.children.map((child) => child.text).join('');
      text = removeExcludedStrings(text);
      toc.push({ text, children: [], isHeading: true });
    }
  });

  return toc;
}
