import gsap from 'gsap';

import { SanityLinkCard } from '#/lib/sanity/queries/linkCard';
import type { SanityLinkCardGrid } from '#/lib/sanity/queries/sections';
import { cn } from '#/lib/utils';
import { Headline } from '#/components/Headline';
import { Link } from '#/components/Link';
import { Media } from '#/components/Media';

const wrapBackgroundCx = gsap.utils.wrap([
  'bg-flame-04',
  'bg-gold-04',
  'bg-lush-04',
  'bg-apple-04',
  'bg-sky-04',
  'bg-amethyst-04',
]);

type CardProps = {
  card: SanityLinkCard;
  index: number;
  count: number;
  key: string;
};

const CompressedCard = (props: CardProps) => (
  <Link
    key={props.key}
    className={cn('group relative col-span-6 850:col-span-4', {
      '850:col-start-3': props.index === 0 && props.count === 2,
      '850:col-start-5': props.index === 0 && props.count === 1,
    })}
    href={props.card.link.href}
    target={props.card.link.target}
  >
    <div className="absolute inset-0 rounded-15 bg-charcoal m:rounded-25"></div>
    <div className="relative isolate size-full overflow-clip rounded-15 border-1 border-grayscale-03 bg-white transition will-change-transform group-hover:-translate-x-5 group-hover:-translate-y-5 group-hover:border-charcoal m:rounded-25 flex">
      <div
        className={cn(
          'absolute inset-0 size-full opacity-0 transition will-change-[opacity] group-hover:opacity-100',
          wrapBackgroundCx(props.index),
        )}
        style={{ background: props.card.hoverColor ?? undefined }}
      ></div>
      <div className="relative flex h-full w-full flex-row p-15 m:p-24 gap-16">
        {props.card.icon?.url ? (
          <Media
            media={props.card.icon}
            className="size-35 m:size-50"
            mediaClassName="object-contain"
            sizes="50px"
          />
        ) : null}
        <div className="flex flex-col">
          <h4 className="break-words text-18 font-[440] leading-110 -tracking-1 m:text-26">
            {props.card.link.title}
          </h4>
          <p className="mt-5 max-w-[220px] text-12 font-light leading-154 m:mt-15 m:text-14">
            {props.card.caption}
          </p>
        </div>
      </div>
    </div>
  </Link>
);

const StandardCard = (props: CardProps) => (
  <Link
    key={props.key}
    className={cn('group relative col-span-6 850:col-span-4', {
      '850:col-start-3': props.index === 0 && props.count === 2,
      '850:col-start-5': props.index === 0 && props.count === 1,
    })}
    href={props.card.link.href}
    target={props.card.link.target}
  >
    <div className="absolute inset-0 rounded-15 bg-charcoal m:rounded-25"></div>
    <div className="relative isolate size-full overflow-clip rounded-15 border-1 border-grayscale-03 bg-white transition will-change-transform group-hover:-translate-x-5 group-hover:-translate-y-5 group-hover:border-charcoal m:rounded-25">
      <div
        className={cn(
          'absolute inset-0 size-full opacity-0 transition will-change-[opacity] group-hover:opacity-100',
          wrapBackgroundCx(props.index),
        )}
        style={{ background: props.card.hoverColor ?? undefined }}
      ></div>
      <div className="relative flex h-full min-h-150 w-full flex-col p-15 m:min-h-250 m:p-30">
        <h4 className="break-words text-18 font-[440] leading-110 -tracking-1 m:text-26">
          {props.card.link.title}
        </h4>
        <p className="mb-15 mt-5 max-w-[220px] text-12 font-light leading-154 m:mt-15 m:text-14">
          {props.card.caption}
        </p>
        {props.card.icon?.url ? (
          <Media
            media={props.card.icon}
            className="mt-auto size-35 m:size-50"
            mediaClassName="object-contain"
            sizes="50px"
          />
        ) : null}
      </div>
    </div>
  </Link>
);

/**
 * @interface SanityLinkCardGrid
 * @property {string} [headline] - Optional headline text for the grid
 * @property {Array<{
 *   _key: string;
 *   link: {
 *     href: string;
 *     target?: string;
 *     title: string;
 *   };
 *   caption?: string;
 *   icon?: {
 *     url: string;
 *   };
 *   hoverColor?: string;
 * }>} cards - Array of link card objects
 * @property {boolean} [compressed] - Optional flag to enable compressed layout
 */

export function LinkCardGrid(
  props: SanityLinkCardGrid & { compressed?: boolean },
) {
  return (
    <div className="p-main">
      <div className="mx-auto max-w-[980px]">
        {props.headline ? (
          <Headline className="mb-40 text-center" value={props.headline} />
        ) : null}
        <div className="grid grid-cols-12 gap-15 l:gap-17">
          {props.cards.map((card, index) => {
            return props.compressed ? (
              <CompressedCard
                key={card._key}
                card={card}
                index={index}
                count={props.cards.length}
              />
            ) : (
              <StandardCard
                key={card._key}
                card={card}
                index={index}
                count={props.cards.length}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
