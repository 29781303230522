import * as React from 'react';

import { cn } from '#/lib/utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex h-50 w-full rounded-10 border-1 border-grayscale-04 bg-cloud px-20 py-18 text-16 font-[440] leading-100 -tracking-1 transition duration-200 placeholder:text-grayscale-04 hover:border-grayscale-05 focus-visible:border-cipher focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-cipher disabled:cursor-not-allowed disabled:opacity-50 m:text-14',
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

Input.displayName = 'Input';

export { Input };
