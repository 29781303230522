import React from 'react';
import Script from 'next/script';
import { ContactFormFields } from '@transcend-io/contact-form-schema';

import { useConsentManager } from '../consent-manager';

declare global {
  interface Window {
    ChiliPiper?: {
      submit: (
        accountName: string,
        routerName: string,
        options: {
          trigger: string;
          lead: Partial<ContactFormFields>;
        },
      ) => void;
    };
  }
}

/**
 * Load the ChiliPiper scheduling script after airgap.js is initialized
 */
export const ChiliPiperScript: React.FC = () => {
  const { airgap } = useConsentManager();
  return (
    <>
      {airgap && (
        <Script
          src="https://transcend.chilipiper.com/concierge-js/cjs/concierge.js"
          strategy="lazyOnload"
        />
      )}
    </>
  );
};
