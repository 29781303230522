import { useEffect, useState } from 'react';
import type { ISO31661Entry, ISO31662Entry } from 'iso-3166';
import { iso31661 } from 'iso-3166/1';
import { iso31662 } from 'iso-3166/2';
import { useFormContext } from 'react-hook-form';

import { cn } from '#/lib/utils';

import { Combobox, type ComboBoxFilter } from './Combobox';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from './FormControls';

/**
 * The countries where we want to also ask for the subdivision (state/province)
 * The subdivisions (i.e., states, provinces) are ISO-3166 alpha-2 codes
 */
const COUNTRIES_TO_GET_SUBDIVISION: Set<ISO31661Entry['alpha2']> = new Set([
  'US',
  'CA',
]);

/**
 * A pared-down list of the subdivisions (i.e., states, provinces) we can show
 */
const ENABLED_SUBDIVISIONS: ISO31662Entry[] = iso31662.filter((subdivision) =>
  COUNTRIES_TO_GET_SUBDIVISION.has(subdivision.parent),
);

const filterCountries: ComboBoxFilter = (search, options) => {
  return search === ''
    ? options
    : options.filter((option) => {
        return (
          option.label.toLowerCase().includes(search.toLowerCase()) ||
          option.value.toLowerCase().includes(search.toLowerCase())
        );
      });
};

export function CountryRegionSelector(props: { label: string }) {
  const { watch, setValue, control } = useFormContext();
  // The subdivisions (states, provinces) to show for the selected country
  const [subdivisions, setSubdivisions] = useState<ISO31662Entry[]>([]);

  // Watch the country value, to set the subdivision list
  const country: ISO31661Entry['alpha2'] | undefined = watch('country');

  // Update subdivision options when the country changes
  useEffect(() => {
    async function getSubdivisions(country: ISO31661Entry['alpha2']) {
      const subdivisionsForCountry = ENABLED_SUBDIVISIONS.filter(
        (subdivision) => subdivision.parent === country,
      );
      setSubdivisions(subdivisionsForCountry);
    }

    if (country && COUNTRIES_TO_GET_SUBDIVISION.has(country)) {
      getSubdivisions(country);
    } else {
      setSubdivisions([]);
    }

    setValue('state', '');
  }, [country, setValue]);

  // The user's countries, recommended based on their browser languages
  const [recommendedCountries, setRecommendedCountries] = useState<
    ISO31661Entry[] | undefined
  >(undefined);

  // Add user's recommended countries based on browser languages
  useEffect(() => {
    try {
      // Map through an ordered list of preferred languages, set by the user's browser
      const foundLanguageCountries = navigator.languages
        .map((language) => {
          // Extract the country code from the preferred language
          const { region } = new Intl.Locale(language);
          if (region) {
            return iso31661.find((entry) => entry.alpha2 === region);
          }
        })
        .filter(Boolean) as ISO31661Entry[];
      setRecommendedCountries(foundLanguageCountries);
    } catch (error) {
      console.warn(error);
    }
  }, []);

  const usersCountries = recommendedCountries ?? [];

  const countries = iso31661
    .filter((country) =>
      usersCountries?.some(
        (userCountry) => userCountry.alpha2 !== country.alpha2,
      ),
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  const options = [...usersCountries, ...countries].map((country) => ({
    value: country.alpha2,
    label: country.name,
  }));

  return (
    <fieldset className="col-span-2 space-y-20">
      <FormField
        name="country"
        control={control}
        defaultValue=""
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel className="sr-only">Country</FormLabel>
              <FormControl>
                <Combobox
                  {...field}
                  autoComplete="country-name"
                  placeholder={props.label}
                  options={options}
                  filter={filterCountries}
                />
              </FormControl>
              <FormDescription />
              <FormMessage className="text-legal mt-4 text-flame-06" />
            </FormItem>
          );
        }}
      />

      <FormField
        name="state"
        control={control}
        disabled={subdivisions === null}
        defaultValue=""
        render={({ field }) => {
          return (
            <FormItem className={cn({ hidden: subdivisions.length === 0 })}>
              <FormLabel className="sr-only">Country</FormLabel>
              <FormControl>
                <Combobox
                  {...field}
                  autoComplete="address-level1"
                  placeholder={`Select ${country === 'US' ? 'state' : 'province'}`}
                  options={subdivisions.map((subdivision) => ({
                    value: subdivision.code,
                    label: subdivision.name,
                  }))}
                />
              </FormControl>
              <FormDescription />
              <FormMessage className="text-legal mt-4 text-flame-06" />
            </FormItem>
          );
        }}
      />
    </fieldset>
  );
}
