import type { SVGProps } from 'react';

export function ArrowRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 15 15" fill="none" {...props}>
      <path
        d="M0.962622 8.48764L11.7151 8.48764L7.01751 13.3084C6.64209 13.6936 6.64209 14.3258 7.01751 14.7111C7.10657 14.8027 7.21235 14.8753 7.3288 14.9249C7.44525 14.9745 7.57009 15 7.69616 15C7.82223 15 7.94707 14.9745 8.06352 14.9249C8.17997 14.8753 8.28575 14.8027 8.37481 14.7111L14.7185 8.20117C14.8077 8.10978 14.8785 8.00122 14.9268 7.88172C14.9751 7.76222 15 7.63411 15 7.50473C15 7.37535 14.9751 7.24725 14.9268 7.12774C14.8785 7.00824 14.8077 6.89969 14.7185 6.8083L8.38444 0.288473C8.29531 0.197015 8.18951 0.124467 8.07307 0.0749711C7.95663 0.0254748 7.83182 -3.11404e-07 7.70579 -3.1884e-07C7.57975 -3.26277e-07 7.45495 0.0254748 7.33851 0.0749711C7.22206 0.124467 7.11626 0.197015 7.02714 0.288472C6.93802 0.37993 6.86732 0.488505 6.81909 0.608C6.77086 0.727494 6.74603 0.855568 6.74603 0.984908C6.74603 1.11425 6.77086 1.24232 6.81909 1.36182C6.86732 1.48131 6.93802 1.58989 7.02714 1.68134L11.7151 6.51194L0.962622 6.51194C0.433181 6.51194 6.49597e-07 6.95647 6.25848e-07 7.49979C6.02099e-07 8.04311 0.43318 8.48764 0.962622 8.48764Z"
        className="fill-current"
      />
    </svg>
  );
}
