import type { SanityBentoBox } from '#/lib/sanity/queries/bentoBoxItem';
import { cn } from '#/lib/utils';
import { Link } from '#/components/Link';

import { Button } from './Button';
import { Headline } from './Headline';
import { Media } from './Media';

export function BentoBox(props: SanityBentoBox) {
  return (
    <div className="p-main">
      <div className="mx-auto max-w-735 l:max-w-980">
        <Headline value={props.headline} className="mb-40 text-center" />
        <div className="grid-cols-12 gap-20 space-y-15 m:space-y-20 l:grid l:space-y-0">
          {props.boxes.map((box, i) => {
            const type = box?.content?._type;
            // Large boxes are the first, second and sixth box as defined in sanity
            const isLargeBox = [0, 1, 5].includes(i);
            if (!type) return null;
            return (
              <div
                key={box.content._key}
                style={{ background: box.background }}
                className={cn(
                  'col-span-4 overflow-hidden rounded-15 m:rounded-25 flex justify-center items-center',
                  {
                    'col-span-8': i === 0 || i === 5,
                    'row-span-2': i === 1,
                  },
                )}
              >
                {type === 'bentoBoxItemMedia' && (
                  <div
                    className={cn('size-full', {
                      'px-15 py-15 s:px-30 s:py-30 m:px-78 m:py-56':
                        box.content.hasMediaPadding,
                    })}
                  >
                    <Media
                      media={box.content.media}
                      className="size-full"
                      mediaClassName={cn({
                        'object-contain': box.content.hasMediaPadding,
                        'object-cover': !box.content.hasMediaPadding,
                      })}
                      sizes={{ m: '735px', l: '650px' }}
                    />
                  </div>
                )}

                {type === 'bentoBoxItemStat' && (
                  <div className="p-56 text-center">
                    <div className="mb-25 text-65 font-[440] leading-105 -tracking-1 m:text-82">
                      {box.content.value}
                    </div>
                    <div className="font-azeret text-11 uppercase leading-150 tracking-5 text-grayscale-06 m:text-12">
                      {box.content.caption}
                    </div>
                  </div>
                )}

                {type === 'bentoBoxItemCta' && (
                  <div className="text-balance p-30 text-center">
                    <Media
                      media={box.content.media}
                      className="mx-auto mb-18 h-auto w-44"
                      sizes="44px"
                    />
                    <h3 className="mb-25 text-22 font-[440] leading-105 -tracking-1">
                      {box.content.headline}
                    </h3>
                    <Button
                      href={box.content.cta.href}
                      target={box.content.cta.target}
                      variant="secondary"
                    >
                      {box.content.cta.title}
                    </Button>
                  </div>
                )}
                {type === 'bentoBoxItemContent' && (
                  <div
                    className={cn(
                      'text-balance p-30 flex size-full',
                      [0, 5].includes(i)
                        ? 'flex-row-reverse gap-20'
                        : 'flex-col',
                    )}
                  >
                    {isLargeBox && (
                      <Media
                        media={box.content?.media}
                        className="mx-auto h-auto w-full max-w-[300px]"
                        sizes={{ m: '50vw', l: '33vw', '1285px': '412px' }}
                      />
                    )}
                    <div className="flex flex-col justify-start">
                      <h3 className="mb-10 text-22 font-[440] leading-105 -tracking-1">
                        {box.content.headline}
                      </h3>
                      <p className="text-16">{box.content.body} </p>
                    </div>
                  </div>
                )}

                {type === 'bentoBoxItemReview' && (
                  <div className="p-30 flex flex-col size-full justify-center">
                    {box.content.hasReviewStars ? (
                      <svg
                        className="mb-25 w-120"
                        viewBox="0 0 120 20"
                        fill="none"
                      >
                        <path
                          d="M10 16.0074L14.8499 18.9407C15.738 19.4783 16.8249 18.6836 16.5911 17.6786L15.3056 12.1626L19.5945 8.44634C20.3775 7.76853 19.9568 6.48303 18.9284 6.40122L13.2839 5.92208L11.0751 0.709949C10.6778 -0.23665 9.32219 -0.23665 8.92485 0.709949L6.71612 5.9104L1.07159 6.38954C0.04319 6.47134 -0.377521 7.75685 0.405468 8.43466L4.69438 12.1509L3.40887 17.6669C3.17514 18.6719 4.26198 19.4666 5.15015 18.929L10 16.0074Z"
                          className="fill-current"
                        />
                        <path
                          d="M35 16.0074L39.8499 18.9407C40.738 19.4783 41.8249 18.6836 41.5911 17.6786L40.3056 12.1626L44.5945 8.44634C45.3775 7.76853 44.9568 6.48303 43.9284 6.40122L38.2839 5.92208L36.0751 0.709949C35.6778 -0.23665 34.3222 -0.23665 33.9249 0.709949L31.7161 5.9104L26.0716 6.38954C25.0432 6.47134 24.6225 7.75685 25.4055 8.43466L29.6944 12.1509L28.4089 17.6669C28.1751 18.6719 29.262 19.4666 30.1501 18.929L35 16.0074Z"
                          className="fill-current"
                        />
                        <path
                          d="M60 16.0074L64.8499 18.9407C65.738 19.4783 66.8249 18.6836 66.5911 17.6786L65.3056 12.1626L69.5945 8.44634C70.3775 7.76853 69.9568 6.48303 68.9284 6.40122L63.2839 5.92208L61.0751 0.709949C60.6778 -0.23665 59.3222 -0.23665 58.9249 0.709949L56.7161 5.9104L51.0716 6.38954C50.0432 6.47134 49.6225 7.75685 50.4055 8.43466L54.6944 12.1509L53.4089 17.6669C53.1751 18.6719 54.262 19.4666 55.1501 18.929L60 16.0074Z"
                          className="fill-current"
                        />
                        <path
                          d="M85 16.0074L89.8499 18.9407C90.738 19.4783 91.8249 18.6836 91.5911 17.6786L90.3056 12.1626L94.5945 8.44634C95.3775 7.76853 94.9568 6.48303 93.9284 6.40122L88.2839 5.92208L86.0751 0.709949C85.6778 -0.23665 84.3222 -0.23665 83.9249 0.709949L81.7161 5.9104L76.0716 6.38954C75.0432 6.47134 74.6225 7.75685 75.4055 8.43466L79.6944 12.1509L78.4089 17.6669C78.1751 18.6719 79.262 19.4666 80.1501 18.929L85 16.0074Z"
                          className="fill-current"
                        />
                        <path
                          d="M110 16.0074L114.85 18.9407C115.738 19.4783 116.825 18.6836 116.591 17.6786L115.306 12.1626L119.595 8.44634C120.378 7.76853 119.957 6.48303 118.928 6.40122L113.284 5.92208L111.075 0.709949C110.678 -0.23665 109.322 -0.23665 108.925 0.709949L106.716 5.9104L101.072 6.38954C100.043 6.47134 99.6225 7.75685 100.405 8.43466L104.694 12.1509L103.409 17.6669C103.175 18.6719 104.262 19.4666 105.15 18.929L110 16.0074Z"
                          className="fill-current"
                        />
                      </svg>
                    ) : null}
                    <h3 className="max-w-360 text-22 font-[440] leading-105 -tracking-1">
                      {box.content.headline}
                    </h3>
                    {box.content.bodyCopy ? (
                      <p className="mt-15">{box.content.bodyCopy}</p>
                    ) : null}
                    <p className="mt-25 text-14 font-[440] leading-116 tracking-0">
                      –{' '}
                      {box.content.url ? (
                        <Link href={box.content.url} target="_blank">
                          {box.content.name}
                        </Link>
                      ) : (
                        box.content.name
                      )}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
