import { forwardRef, type Ref } from 'react';

import { cn } from '#/lib/utils';

type Props = {
  className?: string;
  children: React.ReactNode;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div';
};

export const Overline = forwardRef(
  (
    { className, children, as = 'div' }: Props,
    ref: Ref<HTMLHeadingElement | HTMLDivElement>,
  ) => {
    const Tag = as;
    return (
      <Tag
        ref={ref}
        className={cn(
          className,
          'text-current/90 font-azeret text-11 font-medium uppercase leading-150 tracking-3 m:text-12 m:tracking-5',
        )}
      >
        {children}
      </Tag>
    );
  },
);

Overline.displayName = 'Overline';
