import { useEffect, useState } from 'react';
import Head from 'next/head';
import { numberSafeParse } from '@repo/shared/helpers';

import { imageUrlBuilder, MediaCondition, MediaProps } from '#/lib/sanity';
import type { SanityImage } from '#/lib/sanity/queries/image';
import { cn, SCREENS } from '#/lib/utils';

type Props = {
  image: SanityImage;
} & MediaProps;

enum ImageState {
  Initial = 'initial',
  Loading = 'loading',
  Loaded = 'loaded',
  Done = 'done',
}

export function Image({
  image,
  className,
  mediaClassName,
  sizes = '100vw',
  aspect = true,
  placeholder = false,
  preload = false,
  style,
}: Props) {
  const src = srcFor(image);
  const srcSet = srcsetFor(image);
  const sizesString = generateSizes(sizes);
  const [status, setStatus] = useState(ImageState.Initial);

  useEffect(() => {
    if (status === ImageState.Initial) {
      setStatus(ImageState.Loading);
    }
  }, [status]);

  function handleLoad() {
    requestAnimationFrame(() => {
      setStatus(ImageState.Loaded);
    });
  }

  function handleTransitionEnd() {
    requestAnimationFrame(() => {
      setStatus(ImageState.Done);
    });
  }

  return (
    <>
      {preload ? (
        <Head>
          <link
            rel="preload"
            as="image"
            imageSrcSet={srcSet}
            imageSizes={sizesString}
          />
        </Head>
      ) : null}
      <div
        className={cn('relative overflow-clip', className)}
        style={{
          aspectRatio: aspect ? `${image.width} / ${image.height}` : undefined,
          ...style,
        }}
      >
        {placeholder && status !== ImageState.Done ? (
          <img
            className={cn(
              'absolute inset-0 size-full scale-105 blur-md',
              mediaClassName,
            )}
            src={image.lqip}
            alt=""
          />
        ) : null}
        {status !== ImageState.Initial ? (
          <img
            onLoad={handleLoad}
            onTransitionEnd={handleTransitionEnd}
            loading="lazy"
            decoding="async"
            src={src}
            srcSet={srcSet}
            sizes={sizesString}
            alt={image.alt ?? ''}
            className={cn(
              'absolute inset-0 size-full object-cover transition-opacity duration-500',
              mediaClassName,
              {
                'opacity-0': status === ImageState.Loading,
              },
            )}
          />
        ) : null}
      </div>
    </>
  );
}

function srcFor(image: SanityImage) {
  return imageUrlBuilder.image(image).auto('format').fit('max').url();
}

function srcsetFor(image: SanityImage) {
  return [50, 100, 250, 400, 650, 768, 1024, 1280, 1536, 2048, 2560, 3072]
    .map(
      (width) =>
        `${imageUrlBuilder
          .image(image)
          .width(width)
          .auto('format')
          .fit('max')
          .url()} ${width}w`,
    )
    .join(',');
}

function generateSizes(sizes: MediaProps['sizes']) {
  let sizeEntries = '';

  if (typeof sizes === 'string') {
    sizeEntries = sizes;
  } else if (Array.isArray(sizes)) {
    sizeEntries = sizes
      .map((size) => {
        if (typeof size === 'string') {
          return size;
        } else if (typeof size === 'object') {
          return generateSizeString(size);
        }
        return '';
      })
      .join(', ');
  } else if (typeof sizes === 'object' && sizes !== null) {
    sizeEntries = generateSizeString(sizes);
  } else {
    sizeEntries = '100vw';
  }

  const sizeEntriesArray = sizeEntries.split(', ');
  const defaultValues = sizeEntriesArray.filter(
    (entry) => !entry.includes('min-width'),
  );

  if (defaultValues.length > 1) {
    console.warn('More than one default value passed to sizes attribute');
  }

  const sortedEntries = sizeEntriesArray.sort((a, b) => {
    const minWidthA = a.match(/\(min-width: (\d+px)\)/)?.[1];
    const minWidthB = b.match(/\(min-width: (\d+px)\)/)?.[1];

    if (minWidthA && minWidthB) {
      return (
        (numberSafeParse(minWidthB.replace('px', '')) ?? 0) -
        (numberSafeParse(minWidthA.replace('px', '')) ?? 0)
      );
    }

    if (minWidthA) return -1;
    if (minWidthB) return 1;
    return 0;
  });

  if (defaultValues.length === 0) {
    sortedEntries.push('100vw');
  }

  return sortedEntries.join(', ');
}

function generateSizeString(sizeObj: MediaCondition) {
  return Object.entries(sizeObj)
    .map(([key, value]) => {
      const minWidth =
        key in SCREENS ? SCREENS[key as keyof typeof SCREENS] : key;
      return `(min-width: ${minWidth}) ${value}`;
    })
    .join(', ');
}
