import { forwardRef } from 'react';
import NextLink from 'next/link';

export const Link = forwardRef<
  HTMLAnchorElement,
  {
    href?: string | null;
    children: React.ReactNode;
    [key: string]: any;
  }
>(({ href, children, ...props }, ref) => {
  if (!href) {
    return <span>{children}</span>;
  }
  const isExternal =
    href.startsWith('http') ||
    href.startsWith('mailto') ||
    href.startsWith('tel');

  if (isExternal) {
    return (
      <a href={href} ref={ref} {...props}>
        {children}
      </a>
    );
  }

  return (
    <NextLink ref={ref} href={href} {...props}>
      {children}
    </NextLink>
  );
});
Link.displayName = 'Link';
