import type { SanityNewsletterListing } from '#/lib/sanity/queries/sections';
import { Link } from '#/components/Link';

import { Headline } from './Headline';
import { Media } from './Media';

export function NewsletterListing(props: SanityNewsletterListing) {
  return (
    <div className="p-main">
      <Headline value={props.headline} className="mb-40 text-center" />
      <div className="mx-auto grid max-w-1235 grid-cols-1 gap-x-20 gap-y-40 m:grid-cols-2 l:grid-cols-3">
        {props.issues.map((issue) => (
          <Link key={issue._key} href={issue.url}>
            <Media
              media={issue.thumbnailImage}
              className="aspect-[16/9] rounded-15 m:rounded-25"
              mediaClassName="object-cover"
              aspect={false}
              placeholder={true}
              sizes={['100vw', { m: '50vw', l: '33vw', '1285px': '412px' }]}
            />
            <Headline
              as="h3"
              value={new Date(issue.publishedAt).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
              })}
              variant={6}
              className="mb-8 mt-15 m:mb-10 m:mt-20"
            />
            <p>{issue.subjectLine}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}
