import { useEffect, type FC } from 'react';
import { useRect } from '@darkroom.engineering/hamo';
import { useGSAP } from '@gsap/react';
import { getContrastRatio } from '@repo/shared/helpers';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

import { useNavTheme } from '#/lib/nav-theme';
import type { SanityPageSection } from '#/lib/sanity/queries/sections';
import { useAppSizes } from '#/lib/use-app-sizes';
import { cn, COLORS } from '#/lib/utils';
import { AnchorPoint } from '#/components/AnchorPoint';
import { BentoBox } from '#/components/BentoBox';
import { CallToAction } from '#/components/CallToAction';
import { CareersFeed } from '#/components/CareersFeed';
import { DocumentTemplate } from '#/components/DocumentTemplate';
import { EditorialCollage } from '#/components/EditorialCollage';
import { Faq } from '#/components/Faq';
import { HeroBasic } from '#/components/HeroBasic';
import { HeroNewsletter } from '#/components/HeroNewsletter';
import { HeroProduct } from '#/components/HeroProduct';
import { HeroTwoUp } from '#/components/HeroTwoUp';
import { LinkCardGrid } from '#/components/LinkCardGrid';
import { LogoGrid } from '#/components/LogoGrid';
import { LogoMarquee } from '#/components/LogoMarquee';
import { NewsletterListing } from '#/components/NewsletterListing';
import { PageSections } from '#/components/PageSections';
import { PartnerGrid } from '#/components/PartnerGrid';
import { PhotoMarquee } from '#/components/PhotoMarquee';
import { PlatformOverview } from '#/components/PlatformOverview';
import { PressListing } from '#/components/PressListing';
import { ProblemVisualization } from '#/components/ProblemVisualization';
import { RelatedContent } from '#/components/RelatedContent';
import { RichAccordion } from '#/components/RichAccordion';
import { RichTextSection } from '#/components/RichTextSection';
import { SolutionsTabs } from '#/components/SolutionsTabs';
import { Stats } from '#/components/Stats';
import { TestimonialCarousel } from '#/components/TestimonialCarousel';
import { TestimonialGrid } from '#/components/TestimonialGrid';
import { TestimonialMarquee } from '#/components/TestimonialMarquee';
import { TestimonialSection } from '#/components/TestimonialSection';
import { ThreeUp } from '#/components/ThreeUp';
import { Timeline } from '#/components/Timeline';
import { TwoUp } from '#/components/TwoUp';
import { TwoUpCard } from '#/components/TwoUpCard';
import { TwoUpForm } from '#/components/TwoUpForm';
import { TwoUpScroller } from '#/components/TwoUpScroller';

type Props = {
  section: SanityPageSection;
  index: number;
};

const COMPONENT_MAP: {
  [K in SanityPageSection['_type']]: FC<
    Extract<SanityPageSection, { _type: K }>
  >;
} = {
  sectionReference: PageSections,
  logoMarquee: LogoMarquee,
  platformOverview: PlatformOverview,
  testimonialSection: TestimonialSection,
  twoUp: TwoUp,
  twoUpScroller: TwoUpScroller,
  testimonialMarquee: TestimonialMarquee,
  callToAction: CallToAction,
  heroBasic: HeroBasic,
  heroProduct: HeroProduct,
  heroTwoUp: HeroTwoUp,
  heroNewsletter: HeroNewsletter,
  threeUp: ThreeUp,
  stats: Stats,
  linkCardGrid: LinkCardGrid,
  faqSection: Faq,
  twoUpCard: TwoUpCard,
  photoMarquee: PhotoMarquee,
  editorialCollage: EditorialCollage,
  partnerGrid: PartnerGrid,
  newsletterListing: NewsletterListing,
  problemVisualization: ProblemVisualization,
  bentoBox: BentoBox,
  careersFeed: CareersFeed,
  testimonialCarousel: TestimonialCarousel,
  testimonialGrid: TestimonialGrid,
  logoGrid: LogoGrid,
  pressListing: PressListing,
  richAccordion: RichAccordion,
  richTextSection: RichTextSection,
  relatedContent: RelatedContent,
  twoUpForm: TwoUpForm,
  documentTemplate: DocumentTemplate,
  anchorPoint: AnchorPoint,
  solutionsTabs: SolutionsTabs,
  timeline: Timeline,
};

function sectionHasBackground(
  section: SanityPageSection,
): section is Extract<SanityPageSection, { background?: string }> {
  return 'background' in section && typeof section.background === 'string';
}

export function PageSection({ section, index }: Props) {
  const Component = COMPONENT_MAP[section._type] as
    | FC<SanityPageSection>
    | undefined;

  /**
   * Logic for vertical spacing between sections
   * ===========================================
   *
   * 1. If the section has a background color, remove the top and bottom margin so
   * sections can stack on top of each other and share a common background color
   * 2. If the section is in the `sectionsWithoutMargin` set, remove the top and bottom margin always
   * 3. If the section is in the `sectionsWithoutMarginTop` set and it has a background, remove the top margin only.
   * 4. If it's the first section, it has no top margin
   * */

  const hasBackground = sectionHasBackground(section);
  const sectionsWithoutMargin = new Set([
    'heroProduct',
    'testimonialMarquee',
    'problemVisualization',
    'sectionReference',
    'anchorPoint',
  ]);
  const sectionsWithoutMarginTop = new Set(['threeUp']);
  const isFirstSection = index === 0;

  let hasMarginTop = true;
  let hasMarginBottom = true;

  if (sectionsWithoutMargin.has(section._type) || hasBackground) {
    hasMarginTop = false;
    hasMarginBottom = false;
  }

  if (sectionsWithoutMarginTop.has(section._type) && hasBackground) {
    hasMarginTop = false;
    hasMarginBottom = true;
  }

  if (isFirstSection) {
    hasMarginTop = false;
  }

  /**
   * Logic for inverting the navigation color desktop only when the section has a background color
   */
  const shouldInvertNav =
    hasBackground &&
    getContrastRatio(
      section.background ?? COLORS.cloud,
      COLORS.grayscale['06'],
    ) < 4.5;

  const { registerSection, unregisterSection } = useNavTheme();

  const { headerHeight } = useAppSizes();
  // @ts-ignore
  const [setRef, rect] = useRect({ debounce: 0, ignoreTransform: true });

  const sectionId = `${section._key}-${index}`;

  useEffect(() => {
    if (shouldInvertNav) {
      const top = isFirstSection ? 0 : rect.top - headerHeight / 2;
      const bottom = rect.bottom - headerHeight / 2;
      if (!isNaN(top) && !isNaN(bottom)) {
        registerSection(sectionId, top, bottom);
      }
    }
    // eslint-disable-next-line
  }, [rect, isFirstSection, shouldInvertNav, headerHeight]);

  useEffect(() => {
    return () => {
      unregisterSection(sectionId);
    };
    // eslint-disable-next-line
  }, []);

  useGSAP(() => {
    if (rect.element) {
      ScrollTrigger.create({
        trigger: rect.element,
        start: 'top bottom',
        end: 'bottom top',
        once: true,
        onEnter: () => {
          rect.element.classList.remove('is-hidden');
        },
      });
    }
  }, [rect.element]);

  if (!Component) {
    return (
      <div key={section._key} className="px-25 py-100 text-center">
        {section._type}
      </div>
    );
  }

  return (
    <section
      ref={setRef}
      key={section._key}
      data-section={section._type}
      className={cn('is-hidden', {
        'mt-60 m:mt-120': hasMarginTop,
        'mb-60 m:mb-120': hasMarginBottom,
      })}
    >
      <Component {...section} sectionIndex={index} />
    </section>
  );
}
